import React from 'react';
import {
  StyleSheet,
  View,
  Image,
  Text,
  ScrollView,
  ImageBackground,
} from 'react-native';
import { RouteProp, useRoute } from '@react-navigation/native';
import ReactMarkdown from 'react-markdown';
import { MoreParams } from '../../utils/types';
import RainbowLine from '../RainbowLine';
import { colourConst, fontFamilyConst } from '../../globalStyles';

const Item = () => {
  const route = useRoute<RouteProp<MoreParams, 'Item'>>();

  if (route.params) {
    const { Information, Image_Url } = route.params.item;
    console.log('route.params.item', route.params.item);
    return (
      <RainbowLine>
        <ImageBackground
          source={require('../../../assets/watermark.png')}
          style={styles.background}
        >
          <ScrollView style={styles.container}>
            <Image style={styles.img} source={Image_Url} />
            <View style={styles.text}>
              <Text style={styles.textBody}>
                <ReactMarkdown>{Information}</ReactMarkdown>
              </Text>
            </View>
          </ScrollView>
        </ImageBackground>
      </RainbowLine>
    );
  } else {
    return null;
  }
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
    flex: 1,
  },
  img: {
    width: '100%',
    aspectRatio: 1.75,
  },
  text: {
    marginHorizontal: 18,
    marginTop: 20,
  },
  textBody: {
    color: colourConst.font,
    fontFamily: fontFamilyConst.regular,
  },
  background: {
    flex: 1,
  },
});

export default Item;
