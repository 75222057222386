import {
  Animated,
  Easing,
  View,
  Text,
  FlatList,
  ListRenderItem,
  StyleSheet,
  Image,
  Button,
} from 'react-native';
import { useQueryWithToken } from '../../query';
import React, { useEffect, useRef, useMemo, ReactElement } from 'react';
import { colourConst, fontFamilyConst, fontSize } from '../../globalStyles';
import { AgendaView, LocationWithMaybeWave } from '../../types';
import { useLogout } from '../../hooks/useLogout';
const NoData = () => (
  <View style={styles.centerContent}>
    <Text style={styles.statusText}>
      There are no items available at this time.
    </Text>
  </View>
);

export interface AgendaLoaderProps<T> {
  queryKey: string;
  queryFunction: (
    token: string,
    locationAndWave: LocationWithMaybeWave | null | undefined
  ) => Promise<readonly T[]>;
  renderItem: ListRenderItem<T>;
  renderHeader?: (
    data: AgendaView[],
    filteredData: AgendaView[]
  ) => ReactElement;
  filterData?: (data: AgendaView[]) => AgendaView[];
  shouldFilterByDate?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint, @typescript-eslint/no-explicit-any
const AgendaLoader = <T extends any>({
  queryKey,
  queryFunction,
  renderItem,
  renderHeader,
  filterData,
  shouldFilterByDate,
}: AgendaLoaderProps<T>) => {
  const { isLoading, isError, data, error } = useQueryWithToken(
    queryKey,
    queryFunction
  );
  const { handleLogout } = useLogout();
  const sortDatabyDate = (data) => {
    if (!data || !Array.isArray(data)) return [];
    return data.sort((a, b) => {
      const aDateTimeBegin = new Date(a.DateTimeBegin);
      const bDateTimeBegin = new Date(b.DateTimeBegin);
      const aDateTimeEnd = new Date(a.DateTimeEnd);
      const bDateTimeEnd = new Date(b.DateTimeEnd);

      if (aDateTimeBegin < bDateTimeBegin) {
        return -1;
      } else if (aDateTimeBegin > bDateTimeBegin) {
        return 1;
      } else {
        if (aDateTimeEnd < bDateTimeEnd) {
          return -1;
        } else if (aDateTimeEnd > bDateTimeEnd) {
          return 1;
        } else {
          return 0;
        }
      }
    });
  };
  const pulse = useRef(new Animated.Value(0));
  const getErrorMessage = (error: unknown) => {
    if (typeof error === 'string') {
      return error;
    }

    if (error instanceof Error) {
      return error.message;
    }

    return 'Something went wrong.';
  };

  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(pulse.current, {
          toValue: 1,
          duration: 1000,
          useNativeDriver: true,
          easing: Easing.ease,
        }),
        Animated.timing(pulse.current, {
          toValue: 0,
          duration: 1000,
          useNativeDriver: true,
          easing: Easing.ease,
        }),
      ])
    ).start();
  }, []);

  const filteredData: AgendaView[] = sortDatabyDate(
    useMemo(() => {
      if (data && Array.isArray(data) && shouldFilterByDate) {
        const currentTime = new Date();
        return data.filter((item) => {
          const dateWithoutTimezone = item.DateTimeEnd.replace('Z', '');
          const dateTimeEnd = new Date(dateWithoutTimezone);
          return currentTime < dateTimeEnd;
        });
      }

      if (data && filterData && !shouldFilterByDate) {
        return filterData(data);
      }

      return data;
    }, [data, filterData, shouldFilterByDate])
  );
  const header = data && filteredData && renderHeader?.(data, filteredData);

  if (isLoading) {
    return (
      <View style={styles.centerContent}>
        <Image
          // eslint-disable-next-line react-native/no-inline-styles
          style={{
            flex: 1,
            width: 100,
            height: 100,
            alignSelf: 'center',
            resizeMode: 'contain',
          }}
          source={require('../../../assets/loader.gif')}
        />
      </View>
    );
  }

  if (isError) {
    return (
      <View style={styles.centerContent}>
        <Text style={[styles.statusText, styles.error]}>
          There was a problem loading your data. Please try logging out and in
          again.
        </Text>
        <Button
          title="Logout"
          onPress={handleLogout}
          color={colourConst.blue}
        />
      </View>
    );
  }

  return (
    <FlatList
      style={styles.list}
      contentContainerStyle={styles.list}
      data={filteredData}
      ListEmptyComponent={NoData}
      renderItem={(item) => renderItem({ ...item, filteredData })}
      ListHeaderComponent={header}
    />
  );
};

export default React.memo(AgendaLoader) as <T>(
  props: AgendaLoaderProps<T>
) => ReactElement;

const styles = StyleSheet.create({
  list: {
    flex: 1,
  },
  centerContent: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  statusText: {
    fontSize: 12,
    color: colourConst.inactiveGray,
    fontFamily: fontFamilyConst.medium,
  },
  error: {
    color: '#CC5500',
    fontSize: fontSize.smallMedium,
    paddingHorizontal: 60,
    marginBottom: 50,
  },
});
