import React from 'react';
import { ImageBackground, StyleSheet, View, Image, Text } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import NotificationItem from '../components/NotificationItem';
import { colourConst, fontFamilyConst, fontSize } from '../globalStyles';
import RainbowLine from '../components/RainbowLine';
import { useSecureContext } from '../context';
import { useNotificationsContext } from '../NotificationsContext';
import { FlatList, TouchableOpacity } from 'react-native-gesture-handler';
import { sortNotifications, filterNotifications } from './utils';
import { useNotifications } from '../hooks/useNotifications';
import usePushNotifications from '../hooks/usePushNotifications';

const Stack = createStackNavigator();

const NotificationsScreen = () => {
  const { token, setState } = useSecureContext();
  const { setUnreadNotifications } = useNotificationsContext();
  const { isLoading, isError, error, refetch, data } = useNotifications();
  const {
    isSubscribed,
    isLoadingSubscriptionStatus,
    notificationPermission,
    requestNotificationPermissionAndSubscribe,
    unsubscribeAndDeleteSubscription,
    subscribeAndSendSubscription,
  } = usePushNotifications(token);
  console.log('IsSubscribed: ', isSubscribed);

  const handleButtonPress = async () => {
    if (notificationPermission === 'default') {
      await requestNotificationPermissionAndSubscribe();
    } else if (notificationPermission === 'granted') {
      if (isSubscribed) {
        await unsubscribeAndDeleteSubscription();
      } else {
        await subscribeAndSendSubscription();
      }
    } else {
      // Handle the case when permission is denied
      console.log('Notification permission denied.');
    }
  };

  if (isLoading) {
    return (
      <Image
        style={styles.loader}
        source={require('../../assets/loader.gif')}
      />
    );
  }

  if (isError) {
    if (
      !!error &&
      typeof error === 'object' &&
      error.toString().includes('401')
    ) {
      setState((prev) => ({
        ...prev,
        token: null,
        state: null,
      }));
    }
    return (
      <View style={styles.pageContainer}>
        {/* <Text style={styles.errorMessage}>
          Notifications are not available at this moment
        </Text> */}
        {isLoadingSubscriptionStatus ? (
          <Image
            style={styles.loader}
            source={require('../../assets/loader.gif')}
          />
        ) : (
          <>
            <Text style={styles.statusText}>
              {isSubscribed
                ? 'Push notifications enabled'
                : 'Push notifications disabled'}
            </Text>
            <TouchableOpacity style={styles.button} onPress={handleButtonPress}>
              <Text style={styles.buttonText}>
                {isSubscribed
                  ? 'Disable Notifications'
                  : 'Enable Notifications'}
              </Text>
            </TouchableOpacity>
          </>
        )}
      </View>
    );
  }

  if (data) {
    // TODO: Change endpoint for notifications when it's ready
    const filteredNotifications = filterNotifications(data);

    return (
      <View style={styles.pageContainer}>
        <RainbowLine>
          <ImageBackground
            source={require('../../assets/watermark.png')}
            style={styles.background}
          >
            <View style={styles.contentContainer}>
              {isLoadingSubscriptionStatus ? (
                <Image
                  style={styles.loader}
                  source={require('../../assets/loader.gif')}
                />
              ) : (
                <>
                  <Text style={styles.statusText}>
                    {isSubscribed
                      ? 'Push notifications are enabled'
                      : 'Push notifications are disabled'}
                  </Text>
                  <TouchableOpacity
                    style={[
                      styles.button,
                      isSubscribed
                        ? styles.buttonEnabled
                        : styles.buttonDisabled,
                    ]}
                    onPress={handleButtonPress}
                  >
                    <Text
                      style={[
                        styles.buttonText,
                        isSubscribed
                          ? styles.buttonTextEnabled
                          : styles.buttonTextDisabled,
                      ]}
                    >
                      {isSubscribed
                        ? 'Disable Push Notifications'
                        : 'Enable Push Notifications'}
                    </Text>
                  </TouchableOpacity>
                </>
              )}
            </View>
            <View style={styles.separator} />
            <FlatList
              data={filteredNotifications}
              renderItem={({ item }) => (
                <NotificationItem
                  NotificationTitle={item?.Title}
                  NotificationBody={item?.Message}
                  canMarkAsRead={item?.CanMarkAsRead}
                  NotificationTime={item?.SentAtUtc}
                  NotificationId={item?.Id}
                  refetchNotifications={refetch}
                  isUnread={item?.NotificationStatusTypeId === 1}
                  isImportant={item?.NotificationPriorityTypeId === 4}
                  NotificationStatusTypeId={item?.NotificationStatusTypeId}
                  NotificationPriorityTypeId={item?.NotificationPriorityTypeId}
                />
              )}
            />
          </ImageBackground>
        </RainbowLine>
      </View>
    );
  }
};

const styles = StyleSheet.create({
  pageContainer: {
    flex: 1,
    width: '100%',
    maxWidth: 600,
    justifyContent: 'center',
    alignSelf: 'center',
  },
  contentContainer: {
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingTop: 20,
    paddingBottom: 10,
  },
  background: {
    flex: 1,
  },
  loader: {
    flex: 1,
    width: 100,
    height: 100,
    alignSelf: 'center',
    resizeMode: 'contain',
  },
  errorMessage: {
    fontSize: 12,
    color: colourConst.inactiveGray,
    fontFamily: fontFamilyConst.medium,
    alignSelf: 'center',
    marginTop: 20,
  },
  button: {
    paddingVertical: 16,
    paddingHorizontal: 32,
    borderRadius: 8,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 16,
  },
  buttonEnabled: {
    backgroundColor: '#BDBDBD',
  },
  buttonDisabled: {
    backgroundColor: colourConst.green,
  },
  buttonText: {
    fontFamily: fontFamilyConst.extraBold,
    fontSize: fontSize.medium,
    textAlign: 'center',
  },
  buttonTextEnabled: {
    color: '#757575',
  },
  buttonTextDisabled: {
    color: '#FFFFFF',
  },

  separator: {
    height: 1.4,
    backgroundColor: colourConst.inactiveGray,
    marginBottom: 10,
  },
  statusText: {
    fontSize: fontSize.medium,
    fontFamily: fontFamilyConst.regular,
    color: colourConst.font,
    marginBottom: 10,
    textAlign: 'center',
  },
});
const NotificationsNavigation = () => {
  return (
    <Stack.Navigator
      initialRouteName="Notifications"
      screenOptions={{
        cardStyle: {
          backgroundColor: colourConst.background,
        },
      }}
    >
      <Stack.Screen
        name="Notifications"
        component={NotificationsScreen}
        options={{
          headerTitle: 'Notifications',
          headerTitleAlign: 'center',
          headerTintColor: colourConst.blue,
          headerTitleStyle: {
            fontFamily: fontFamilyConst.bold,
            fontSize: fontSize.large,
          },
        }}
      />
    </Stack.Navigator>
  );
};

export default React.memo(NotificationsNavigation);
