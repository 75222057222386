export enum ActivityType {
  TourOrActivity = 'Tour or Activity',
  AgendaItem = 'Agenda Item',
  GroupEvent = 'Group Event',
  EveningActivity = 'Evening Activity',
  Meeting = 'Meeting',
}
export enum BookingStatus {
  Bookable = 'Bookable',
  Waitlist = 'WaitList',
  FullyBooked = 'FullyBooked',
}
export enum BookingType {
  Booking = 'Booking',
  Waitlist = 'Waitlist',
  Reserved = 'Reserved',
}
export enum TimeslotType {
  TimeslotEvent = 'TimeslotEvent',
  TimeslotBooking = 'TimeslotBooking',
  TimeslotWaitlist = 'TimeslotWaitlist',
}
export enum DigitalAssetType {
  Social = 1,
  Photos,
  Videos,
  DelegateImages = 5,
  WebGeneral,
  MobileGeneral,
}
