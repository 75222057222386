import React, { useState, useEffect } from 'react';
import { Text, TextStyle } from 'react-native';

interface TruncatedTextProps {
  text: string;
  maxLength: number;
  style?: TextStyle;
}

const TruncatedText: React.FC<TruncatedTextProps> = ({
  text,
  maxLength,
  style,
}) => {
  const [showFullText, setShowFullText] = useState(false);
  const [truncatedText, setTruncatedText] = useState('');

  useEffect(() => {
    if (text && text.length > maxLength) {
      setTruncatedText(`${text.slice(0, maxLength)}...`);
    } else {
      setTruncatedText(text);
    }
  }, [text, maxLength]);

  return (
    <Text onPress={() => setShowFullText(!showFullText)} style={style}>
      {showFullText || (text ? text.length <= maxLength : true)
        ? text
        : truncatedText}
    </Text>
  );
};

export default TruncatedText;
