import { Text, StyleSheet } from 'react-native';
import {
  colourConst,
  fontSize,
  fontFamilyConst,
  timeslots,
} from '../../../globalStyles';
import { TextProps } from '../../../utils/types';
import React from 'react';

const TextTypeTimeslot = ({ children, isBooked = true }: TextProps) => {
  const color = isBooked ? colourConst.white : colourConst.font;
  return <Text style={{ ...styles.text, color }}>{children}</Text>;
};

const styles = StyleSheet.create({
  text: {
    fontFamily: fontFamilyConst.extraBold,
    fontSize: fontSize.smallRegular,
    color: colourConst.font,
    marginRight: timeslots.timeMargin,
    textAlign: 'right',
    lineHeight: timeslots.lineHeight,
  },
});

export default TextTypeTimeslot;
