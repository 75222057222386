export const gradientColours = [
  '#1E3B8D',
  '#0A6226',
  '#118C25',
  '#AC9300',
  '#D4C100',
  '#CE4400',
  '#D2000C',
  '#8B060F',
  '#D30077',
  '#8B3E8D',
  '#2A80BC',
  '#5D1276',
  '#1E3B8D',
];

export const goldenGradientColours = [
  '#b27d2c',
  '#8d5d14',
  '#c68d41',
  '#b97e2c',
  '#a87024',
  '#c5893b',
  '#e7af5f',
  '#f9de8f',
  '#d2af63',
  '#a67428',
  '#c69b4f',
  '#f4dd91',
  '#c68e41',
];
export const locations = [
  0, 0.08, 0.17, 0.26, 0.33, 0.41, 0.51, 0.6, 0.7, 0.78, 0.86, 0.93, 1,
];

function getColoursAndLocations(isGolden) {
  const colours = isGolden ? goldenGradientColours : gradientColours;
  return {
    colours,
    locations,
  };
}
export default getColoursAndLocations;
