import React from 'react';
import {
  Image,
  TouchableOpacity,
  Text,
  StyleSheet,
  ImageBackground,
} from 'react-native';
import { NavigationProp, useFocusEffect } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { useNavigation } from '@react-navigation/native';
import { colourConst, fontFamilyConst, fontSize } from '../globalStyles';
import { SocialCircleParams } from '../utils/types';
import { useSecureContext } from '../context';
import { useGetPostsWithToken } from '../hooks/useGetPostsWithToken';
import SocialPost from '../components/SocialPost';
import { FlatList } from 'react-native-gesture-handler';
import CreatePost from '../components/CreatePost';
import Comments from '../components/Comments';
import RainbowLine from '../components/RainbowLine';
const Stack = createStackNavigator();

const SocialCircleScreen = () => {
  const { locationAndWave, setState } = useSecureContext();

  const { posts, isLoading, isError, error, refetch } = useGetPostsWithToken();
  useFocusEffect(
    React.useCallback(() => {
      refetch();
    }, [refetch])
  );
  if (isLoading) {
    return (
      <Image
        style={styles.loader}
        source={require('../../assets/loader.gif')}
      />
    );
  }

  if (isError) {
    console.log('Error in SocialCircleScreen: ', error);
    if (
      !!error &&
      typeof error === 'object' &&
      'status' in error &&
      error.status === 401
    ) {
      setState((prev) => ({
        ...prev,
        token: null,
        state: null,
      }));
    }
    return <Text>Something went wrong {error?.message}</Text>;
  }
  return (
    <RainbowLine>
      <ImageBackground
        source={require('../../assets/watermark.png')}
        style={styles.background}
      >
        <FlatList
          style={styles.postFeed}
          data={posts?.data}
          contentContainerStyle={styles.container}
          showsVerticalScrollIndicator={false}
          renderItem={({ item }) => (
            <SocialPost
              imageUrl={item.attributes.Image_Url}
              mediaType={item.attributes.Media_Type}
              text={item.attributes.Text}
              date={item.attributes.createdAt}
              comments={item.attributes.comments?.data}
              likes={item.attributes.likes?.data}
              id={item.id}
              firstName={item.attributes.Given_Name}
              lastName={item.attributes.Family_Name}
              participantId={item.attributes.Participant_Id}
              refetch={refetch}
            />
          )}
        />
      </ImageBackground>
    </RainbowLine>
  );
};

const SocialCircleHeaderRight = () => {
  const { locationAndWave } = useSecureContext();
  const isGuest = locationAndWave?.wave?.ProjectRoles.find(
    (role) => role.Reference === 'Guest'
  );
  const navigation = useNavigation<NavigationProp<SocialCircleParams>>();
  if (!isGuest) {
    return (
      <TouchableOpacity
        onPress={() => {
          navigation.navigate('New Post');
        }}
        style={styles.newContainer} // Add this line
      >
        <Text style={styles.new}>+</Text>
      </TouchableOpacity>
    );
  }
  return null;
};

const NewPostHeaderRight = () => {
  const navigation = useNavigation<NavigationProp<SocialCircleParams>>();

  return (
    <TouchableOpacity
      onPress={() => {
        navigation.navigate('Comments', { text: 'FIXME' });
      }}
    >
      <Text>Next</Text>
    </TouchableOpacity>
  );
};

const NewPostMediaHeaderLeft = () => {
  const navigation = useNavigation<NavigationProp<SocialCircleParams>>();
  return (
    <TouchableOpacity
      onPress={() => {
        navigation.popToTop();
      }}
    >
      <Text style={styles.close}>+</Text>
    </TouchableOpacity>
  );
};

const SocialCircleNavigation = () => {
  return (
    <Stack.Navigator
      initialRouteName="SocialCircle"
      screenOptions={{
        cardStyle: {
          backgroundColor: colourConst.background,
        },
      }}
    >
      <Stack.Screen
        name="Social Circle"
        component={SocialCircleScreen}
        options={{
          headerTitle: 'Social Circle',
          headerTitleAlign: 'center',
          headerTintColor: colourConst.blue,
          headerTitleStyle: {
            fontFamily: fontFamilyConst.bold,
            fontSize: fontSize.large,
          },
          headerRight: SocialCircleHeaderRight,
        }}
      />
      <Stack.Screen
        name="New Post"
        component={CreatePost}
        options={{
          headerTitle: 'New Post',
          headerTitleAlign: 'center',
          headerTintColor: colourConst.blue,
          headerTitleStyle: {
            fontFamily: fontFamilyConst.bold,
            fontSize: fontSize.large,
          },
          // headerRight: NewPostHeaderRight,
          headerLeft: NewPostMediaHeaderLeft,
        }}
      />
      <Stack.Screen
        name="Comments"
        component={Comments}
        options={{
          headerTitle: 'Comments',
          headerTitleAlign: 'center',
          headerTintColor: colourConst.blue,
          headerTitleStyle: {
            fontFamily: fontFamilyConst.bold,
            fontSize: fontSize.large,
          },
          // headerRight: NewPostMediaHeaderRight,
        }}
      />
    </Stack.Navigator>
  );
};
const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    justifyContent: 'center',
  },
  postFeed: {
    flex: 1,
    width: 358,
    alignSelf: 'center',
  },
  loader: {
    flex: 1,
    width: 100,
    height: 100,
    alignSelf: 'center',
    resizeMode: 'contain',
  },
  newContainer: {
    height: 20,
    width: 20,
    marginTop: 5,
    marginRight: 10,
    borderRadius: 5,
    borderWidth: 2,
    borderColor: colourConst.blue,
    justifyContent: 'center',
    alignItems: 'center',
  },
  new: {
    color: colourConst.blue,
    fontFamily: fontFamilyConst.regular,
    fontSize: 25,
  },
  close: {
    color: colourConst.font,
    fontFamily: 'Roboto',
    fontSize: 35,
    fontWeight: 100,
    marginLeft: 10,
    transform: [{ rotate: '45deg' }],
  },
  background: {
    flex: 1,
  },
});

export default React.memo(SocialCircleNavigation);
