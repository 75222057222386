import React from 'react';
import { View, StyleSheet, ImageSourcePropType } from 'react-native';
import { Image } from 'expo-image';
import TextUser from '../TextComponents/Home/TextUser';
import TextIntro from '../TextComponents/Home/TextIntro';
import UserPoints from '../UserPoints';
import Line from '../Line';
import Weather from '../Weather';
import { useSecureContext } from '../../context';
import {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
  Easing,
} from 'react-native-reanimated';

export interface HomeHeaderProps {
  firstName: string;
  location?: string;
  numberOfPoints?: string;
}
const locationMap = {
  Austin: 'loc01',
  'Lisbon - Epic Sana': 'loc02',
  'Lisbon - Corinthia': 'loc02',
  'Big Island': 'loc03',
  'Riviera Maya': 'loc04',
};

const HomeHeader = ({
  firstName,
  location,
  numberOfPoints,
}: HomeHeaderProps) => {
  const { locationAndWave } = useSecureContext();
  const isGoldenCircleWinner = locationAndWave?.wave?.ProjectRoles?.find(
    (wave) => wave.Reference === 'GCA'
  );
  /////////////////////// ANIMATION ///////////////////////
  const opacityImageAnimated = useSharedValue(1);
  const animatedImageStyle = useAnimatedStyle(() => ({
    opacity: opacityImageAnimated.value,
  }));
  ///
  if (!location) return null;
  const locationGUID = locationMap[location];
  const imageName = `${locationGUID}_${
    isGoldenCircleWinner ? 'GoldenCircle' : 'Winner'
  }.png`;
  let finalImage: ImageSourcePropType;
  if (imageName === 'loc01_GoldenCircle.png') {
    finalImage = require('../../../assets/loc01_GoldenCircle.png');
  } else if (imageName === 'loc01_Winner.png') {
    finalImage = require('../../../assets/loc01_Winner.png');
  } else if (imageName === 'loc02_GoldenCircle.png') {
    finalImage = require('../../../assets/loc02_GoldenCircle.png');
  } else if (imageName === 'loc02_Winner.png') {
    finalImage = require('../../../assets/loc02_Winner.png');
  } else if (imageName === 'loc03_GoldenCircle.png') {
    finalImage = require('../../../assets/loc03_GoldenCircle.png');
  } else if (imageName === 'loc03_Winner.png') {
    finalImage = require('../../../assets/loc03_Winner.png');
  } else if (imageName === 'loc04_GoldenCircle.png') {
    finalImage = require('../../../assets/loc04_GoldenCircle.png');
  } else {
    finalImage = require('../../../assets/loc04_Winner.png');
  }

  return (
    <View style={style.container}>
      <Image
        source={finalImage}
        contentFit="contain"
        style={[style.image, animatedImageStyle]}
        transition={{
          duration: 300,
          timing: 'linear',
        }}
        onLoadEnd={() => {
          opacityImageAnimated.value = withTiming(1, {
            duration: 300,
            easing: Easing.linear,
          });
        }}
      />
      <TextUser>Hi {firstName}</TextUser>
      <TextIntro>{`Welcome to ${location}!`}</TextIntro>
      <TextIntro>Here&apos;s what your day looks like today.</TextIntro>
      <UserPoints numberOfPoints={numberOfPoints} />
      <Line />
      <Weather location={locationAndWave?.Reference} />
    </View>
  );
};
const style = StyleSheet.create({
  container: {
    flex: 1,
  },
  image: {
    flex: 1,
    width: '100%',
    aspectRatio: 2.505,
  },
});

export default HomeHeader;
