import { SocialPost } from '../types';
import Constants from 'expo-constants';

const { STRAPI_BASE_URL } = Constants.expoConfig!.extra!;
const strapiWaves = {
  '519D24EC-1200-4D96-80DC-A56129DEFF0D': 1,
  '268578F1-F878-41B2-A2F9-F0FD5985DB73': 2,
  '4A5D3940-9A69-47CD-8478-2D57EBBED96B': 3,
  'EE3E5A7B-7E92-445F-81CB-6B1A9029E2A6': 4,
  'A5AB5D4C-2B4C-42CA-91BE-BDA3B2457974': 5,
  'BF9EF31D-005E-475F-9D29-EADD480260C3': 6,
  'EDB712E4-074E-49D9-86A9-9927D4B5AA6F': 7,
  '0eb4ba20-cd4a-4b78-9cfd-bc614055197c': 11,
  '213aceb4-adec-465f-8c4f-69e42af04440': 12,
  'a72a0c1d-72ce-4e1b-8511-9563239e9057': 13,
  '031f471c-4d52-43ef-ae34-02b251124f82': 19,
  'b559274c-634a-4dd4-8b69-f988e98045a3': 20,
  '8b44f84a-09b6-4292-9203-c1117ee9d82e': 17,
  'c22dfc28-5546-45b5-9b84-2be4e41e24ce': 18,
  '5d811713-cac2-4a8e-a6b9-054448890363': 14,
  'f938f2c0-fbbe-4396-b755-61b73142dcc5': 15,
  '59bee3b6-c2be-491d-bba0-96d26afe312a': 16,
};
export const getSocialPosts = (token: string, wave: string) => {
  const pagesUrl = `${STRAPI_BASE_URL}/api/social-posts?filters[wave][UUID][$eq]=${wave}&sort=createdAt%3Adesc&populate=*`;
  return fetch(pagesUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json() as Promise<SocialPost>;
    }

    throw response;
  });
};

export const createPost = (
  token: string,
  wave: string,
  imageUrl: string,
  inputText: string,
  givenName: string,
  familyName: string,
  participantId: string,
  fileType: string
) => {
  const pagesUrl = `${STRAPI_BASE_URL}/api/social-posts`;
  const strapiWaveId = strapiWaves[wave];
  return fetch(pagesUrl, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      data: {
        Text: inputText,
        Image_Url: imageUrl,
        wave: strapiWaveId,
        Given_Name: givenName,
        Family_Name: familyName,
        Participant_Id: participantId,
        Media_Type: fileType,
      },
    }),
  }).then((response) => {
    if (response.ok) {
      return response.json() as Promise<SocialPost>;
    }
    console.log(response);

    throw response;
  });
};
