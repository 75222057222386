import { View, StyleSheet } from 'react-native';
import React from 'react';
import TextCardInfo from '../TextComponents/Home/TextCardInfo';
import { InfoActivityProps } from '../../utils/types';
import TextCardInfoTitle from '../TextComponents/Home/TextCardInfoTitle';
import { useTourInfo } from './utils';
const TourInfo = ({ activityViewDetail }: InfoActivityProps) => {
  const { description, itinerary, additionalInfo, weather, location } =
    useTourInfo(activityViewDetail);
  return (
    <View style={styles.container}>
      {description && (
        <>
          <TextCardInfoTitle>Description:</TextCardInfoTitle>
          <TextCardInfo>{description}</TextCardInfo>
        </>
      )}
      {location && (
        <>
          <TextCardInfoTitle>Location:</TextCardInfoTitle>
          <TextCardInfo>{location}</TextCardInfo>
        </>
      )}
      {itinerary && (
        <>
          <TextCardInfoTitle>Itinerary:</TextCardInfoTitle>
          <TextCardInfo>{itinerary}</TextCardInfo>
        </>
      )}
      {(additionalInfo || weather) && (
        <>
          <TextCardInfoTitle>Additional Information:</TextCardInfoTitle>
          <TextCardInfo>{additionalInfo}</TextCardInfo>
          <TextCardInfo>{weather}</TextCardInfo>
        </>
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    marginTop: 25,
  },
});

export default TourInfo;
