import React from 'react';
import { StyleSheet,  useWindowDimensions } from 'react-native';
import { TextProps } from '../../../utils/types';
import { fontFamilyConst, colourConst } from '../../../globalStyles';
import Animated, {
  withTiming,
  withDelay,
  Easing,
} from 'react-native-reanimated';
import useTextAnimation from '../../../hooks/useTextAnimation';

const TextLoginTitle = ({ children }: TextProps) => {
  const { textOpacity, animatedTextStyle } = useTextAnimation();
  const windowDimensions = useWindowDimensions();
  const fontSize =
    windowDimensions.width <= 320 || windowDimensions.height <= 800 ? 18 : 30;
  return (
    <Animated.Text
      style={[style.text, animatedTextStyle, { fontSize: fontSize }]}
      numberOfLines={3}
      onLayout={() => {
        textOpacity.value = withDelay(
          500,
          withTiming(1, {
            duration: 500,
            easing: Easing.linear,
          })
        );
      }}
    >
      {children}
    </Animated.Text>
  );
};
const style = StyleSheet.create({
  text: {
    fontFamily: fontFamilyConst.bold,
    color: colourConst.blue,
    textAlign: 'center',
    textAlignVertical: 'center',
    marginTop: '0%',
    marginBottom: '3%',
    marginHorizontal: '10%',
    flexShrink: 1,
  },
});
export default TextLoginTitle;
