import { Gesture } from 'react-native-gesture-handler';
import { useState } from 'react';
import {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
} from 'react-native-reanimated';

export const useCollapibleCard = () => {
  const isExpanded = useSharedValue(false);
  const [stateExpanded, setStateExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const expandableStyle = useAnimatedStyle(() => {
    return {
      height: withTiming(isExpanded.value ? contentHeight + 10 : 0),
    };
  });
  const backgroundAnimated = useAnimatedStyle(() => {
    return {
      backgroundColor: withTiming(
        isExpanded.value ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.8)'
      ),
      borderBottomLeftRadius: isExpanded.value ? 0 : 15,
      borderBottomRightRadius: isExpanded.value ? 0 : 15,
    };
  });

  const touchHandler = Gesture.Tap().onEnd(
    () => (
      (isExpanded.value = !isExpanded.value), setStateExpanded(!stateExpanded)
    )
  );

  const handlerLayout = (event: {
    nativeEvent: { layout: { height: number } };
  }) => {
    const newHeight = event.nativeEvent.layout.height;

    setContentHeight(newHeight);
  };
  return {
    expandableStyle,
    backgroundAnimated,
    touchHandler,
    stateExpanded,
    handlerLayout,
    contentHeight,
  };
};
