import React from 'react';
import { View, StyleSheet } from 'react-native';
import TextCardData from '../TextComponents/Home/TextCardData';
import { colourConst } from '../../globalStyles';
interface cardProps {
  text: string;
}
const CardData = ({ text }: cardProps) => {
  return (
    <View style={style.container}>
      <TextCardData>{text}</TextCardData>
    </View>
  );
};
const style = StyleSheet.create({
  container: {
    backgroundColor: colourConst.white,
    borderRadius: 10,
    paddingHorizontal: 10,
    paddingVertical: 0,
    justifyContent: 'center',
    marginHorizontal: 10,
    marginVertical: 15,
  },
});

export default CardData;
