import { StyleSheet, Switch, View } from 'react-native';
import React from 'react';
import TextTitleItem from '../TextComponents/TextTitleItem';
import Label from '../TextComponents/Label';
import { colourConst } from '../../globalStyles';
import { ToggleButtonProps } from '../../../src/utils/types';

const ToggleButton = ({ handler, label, value, text }: ToggleButtonProps) => {
  return (
    <View style={styles.switchContainer}>
      <TextTitleItem>{text}</TextTitleItem>
      <View style={styles.tag}>
        <Label>{label.left}</Label>
        <Switch
          style={styles.switch}
          onValueChange={handler}
          value={value}
          trackColor={{
            false: colourConst.blue,
            true: colourConst.blue,
          }}
          thumbColor={colourConst.white}
          activeThumbColor={colourConst.white}
        />
        <Label>{label.right}</Label>
      </View>
    </View>
  );
};

export default ToggleButton;

const styles = StyleSheet.create({
  switch: {
    width: 15,
    height: 25,
    marginHorizontal: 10,
  },
  switchContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
    marginHorizontal: 6,
  },
  tag: {
    width: 120,
    flexDirection: 'row',
  },
});
