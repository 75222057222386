import {
  ImageBackground,
  View,
  StyleSheet,
  Pressable,
  useWindowDimensions,
  Text,
} from 'react-native';
import { colourConst, fontFamilyConst } from '../globalStyles';
import { useAuthorize } from './api.web.ts';
import TextLogin from '../components/TextComponents/TextLogin';
import TextLoginTitle from '../components/TextComponents/TextLoginTitle';
import TextLoginSubTitle from '../components/TextComponents/TextLoginSubTitle';
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
  withDelay,
} from 'react-native-reanimated';
import TextRequestPassword from '../components/TextComponents/Login/TextRequestPassword';
import useLogin from './useLogin';
import PasswordRequestForm from '../components/PasswordRequestForm';
import React from 'react';

const Login = () => {
  const authorizeGartner = useAuthorize('gartner');
  const authorizeGuest = useAuthorize('guest');
  const { height, width, scale, fontScale } = useWindowDimensions();

  /////////////////////// ANIMATION ///////////////////////

  const ImageBackgroundAnimated =
    Animated.createAnimatedComponent(ImageBackground);
  const opacityAnimated = useSharedValue(0);
  const translateYAnimated = useSharedValue(0);
  const animatedImageBackgroundStyle = useAnimatedStyle(() => ({
    opacity: opacityAnimated.value,
    transform: [{ translateY: translateYAnimated.value }],
  }));
  ////
  const AnimatedPressable = Animated.createAnimatedComponent(Pressable);
  const opacityAnimatedPressable = useSharedValue(0);
  const animatedPressableStyle = useAnimatedStyle(() => ({
    opacity: opacityAnimatedPressable.value,
  }));
  const { isRequested, handleIsRequested, setIsRequested } = useLogin();

  return (
    <View style={{ ...styles.pageContainer, height: height }}>
      <ImageBackgroundAnimated
        source={require('../../assets/GWC23Login.png')}
        style={[styles.img, animatedImageBackgroundStyle]}
        resizeMode="cover"
        onLoad={() => {
          opacityAnimated.value = withTiming(1, {
            duration: 300,
            easing: Easing.circle,
          });
          translateYAnimated.value = withTiming(0, {
            duration: 500,
            easing: Easing.linear,
          });
          opacityAnimatedPressable.value = withDelay(
            1000,
            withTiming(1, {
              duration: 500,
              easing: Easing.linear,
            })
          );
        }}
      ></ImageBackgroundAnimated>
      <Animated.View style={styles.textContainer}>
        {isRequested ? (
          <Text style={styles.instruction}>ENTER YOUR EMAIL ADDRESS:</Text>
        ) : (
          <TextLoginTitle>
            WELCOME TO GARTNER WINNERS CIRCLE 2023
          </TextLoginTitle>
        )}
        {!isRequested && (
          <>
            <AnimatedPressable
              style={[styles.button, animatedPressableStyle]}
              onPress={authorizeGartner}
            >
              <TextLogin>GARTNER ASSOCIATES</TextLogin>
            </AnimatedPressable>
            <AnimatedPressable
              style={[
                styles.button,
                styles.buttonGuests,
                animatedPressableStyle,
              ]}
              onPress={authorizeGuest}
            >
              <TextLogin>GUESTS</TextLogin>
            </AnimatedPressable>
            <AnimatedPressable
              style={animatedPressableStyle}
              onPress={() => setIsRequested(true)}
            >
              <TextRequestPassword>request guest password</TextRequestPassword>
            </AnimatedPressable>
          </>
        )}
        {isRequested && (
          <PasswordRequestForm handleIsRequested={handleIsRequested} />
        )}
        <TextLoginSubTitle>
          YOUR GARTNER WINNERS CIRCLE DIGITAL GUIDE
        </TextLoginSubTitle>
      </Animated.View>
    </View>
  );
};

export default Login;

const styles = StyleSheet.create({
  pageContainer: {
    flex: 1,
    width: '100%',
    height: '100%',
    maxWidth: 600,
    justifyContent: 'center',
    alignSelf: 'center',
    position: 'relative',
    overflow: 'hidden',
  },
  button: {
    alignSelf: 'center',
    justifyContent: 'flex-end',
    paddingVertical: 6,
    paddingHorizontal: 6,
    borderRadius: 20,
    elevation: 3,
    backgroundColor: colourConst.blue,
    marginBottom: '5%',
    width: '50%',
    maxWidth: '60%',
    border: '2px solid #fff',
    textAlign: 'center',
  },
  buttonGuests: {
    backgroundColor: colourConst.inactiveGray,
    marginBottom: '3%',
  },
  img: {
    flex: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    transform: [{ scale: 1 }],
    position: 'absolute',
    bottom: '37%',
    // overflow: 'hidden',
  },
  textContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    bottom: '5%',
  },
  instruction: {
    fontFamily: fontFamilyConst.medium,
    color: colourConst.font,
    textAlign: 'center',
  },
});
