import React from 'react';
import { View, StyleSheet } from 'react-native';
import TextUserPoints from '../TextComponents/Home/TextUserPoints';
import { colourConst, shadowConst } from '../../globalStyles';
import TextPoints from '../TextComponents/Home/TextPoints';

const UserPoints = ({ numberOfPoints }: { numberOfPoints?: string }) => {
  return numberOfPoints ? (
    <View style={style.container}>
      <TextUserPoints content={`You have `} />
      <TextPoints>{numberOfPoints}</TextPoints>
      <TextUserPoints content={` Circle Lounge points`} />
    </View>
  ) : null;
};

const style = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: colourConst.white,
    borderRadius: 10,
    marginTop: 10,
    paddingTop: 8,
    paddingBottom: 8,
    shadowOpacity: shadowConst.opacity,
    shadowOffset: {
      width: shadowConst.width,
      height: shadowConst.height,
    },
    elevation: shadowConst.elevation,
    shadowRadius: shadowConst.radius,
    alignSelf: 'center',
    width: '90%',
  },
});

export default UserPoints;
