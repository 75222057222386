import { useQuery } from 'react-query';
import { useSecureContext } from '../context';
import { getNotifications } from '../helpers/notifications';

export const useNotifications = () => {
  const { token, locationAndWave } = useSecureContext();
  const waveId = locationAndWave?.wave?.ProjectId;
  const { isLoading, isError, error, refetch, data } = useQuery(
    ['notifications', waveId],
    () => getNotifications(token, waveId),
    {
      refetchOnWindowFocus: true,
      enabled: !!token,
    }
  );

  return { isLoading, isError, error, refetch, data };
};
