import React, { useState, useRef, useEffect } from 'react';
import {
  SafeAreaView,
  View,
  TextInput,
  FlatList,
  Text,
  StyleSheet,
  TouchableOpacity,
  Image,
} from 'react-native';
import { useQuery } from 'react-query';
import { createComment, getComments } from '../../helpers/comments';
import { useSecureContext } from '../../context';
import { colourConst, fontFamilyConst, fontSize } from '../../globalStyles';
import RainbowLine from '../RainbowLine';
import { timeSince } from '../../helpers/date-time';

const Comment = ({ userName, text, date }) => (
  <View style={styles.commentContainer}>
    <View style={styles.commentContent}>
      <Text style={styles.commentHeader}>
        {userName} <Text style={styles.timestamp}>{timeSince(date)}</Text>
      </Text>
      <Text style={styles.comment}>{text}</Text>
    </View>
  </View>
);

const Comments = (props) => {
  const textInputRef = useRef(null);

  // Use useEffect to focus on the TextInput when the component mounts
  useEffect(() => {
    if (textInputRef.current) {
      textInputRef.current.focus();
    }
  }, []);
  const socialPostId = props.route.params.id;
  const { contextTokens, userData, locationAndWave } = useSecureContext();
  const isGuest = locationAndWave?.wave?.ProjectRoles.find(
    (role) => role.Reference === 'Guest'
  );
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const { data, isLoading, isError, error, refetch } = useQuery(
    'comments',
    () => getComments(contextTokens?.CmsApiAccessKey, socialPostId)
  );

  const handlePost = () => {
    if (!isGuest) {
      createComment(
        contextTokens?.CmsApiAccessKey,
        newComment,
        socialPostId,
        userData?.FirstName,
        userData?.LastName
      ).then((res) => {
        console.log('res', res);
        refetch();
      });
      setNewComment('');
    }
  };
  if (isLoading) {
    return (
      <Image
        style={styles.loader}
        source={require('../../../assets/loader.gif')}
      />
    );
  }
  return (
    <RainbowLine>
      <SafeAreaView style={styles.container}>
        <FlatList
          data={data?.data}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item }) => (
            <Comment
              userName={`${item.attributes.Given_Name} ${item.attributes.Family_Name}`}
              text={item.attributes.Text}
              date={item.attributes.createdAt}
            />
          )}
        />
        {!isGuest && (
          <View style={styles.inputContainer}>
            <View style={styles.inputWrapper}>
              <TextInput
                ref={textInputRef}
                style={styles.textInput}
                placeholder="add a comment"
                value={newComment}
                onChangeText={setNewComment}
              />
              <TouchableOpacity onPress={handlePost}>
                <Text style={styles.postText}>Post</Text>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </SafeAreaView>
    </RainbowLine>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colourConst.background,
  },
  commentContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: 10,
  },
  userImage: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginRight: 10,
  },
  commentContent: {
    flex: 1,
  },
  commentHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 10,
    fontFamily: fontFamilyConst.regular,
    fontWeight: '600',
    color: colourConst.font,
  },
  timestamp: {
    marginLeft: 5,
    fontWeight: 'normal',
    color: colourConst.font,
    fontFamily: fontFamilyConst.regular,
  },
  comment: {
    fontSize: fontSize.smallRegular,
    fontFamily: fontFamilyConst.regular,
    color: colourConst.font,
    lineHeight: 15,
  },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginBottom: 10,
  },
  inputWrapper: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 10,
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginRight: 10,
    backgroundColor: 'rgba(87, 87, 86, 0.5)',
  },
  textInput: {
    flex: 1,
    fontFamily: fontFamilyConst.regular,
    color: '#DADADA',
    outlineWidth: 0,
  },
  postText: {
    color: colourConst.blue,
    fontFamily: fontFamilyConst.regular,
    paddingRight: 10,
    fontSize: 13,
    marginLeft: 10,
  },
  loader: {
    flex: 1,
    width: 100,
    height: 100,
    alignSelf: 'center',
    resizeMode: 'contain',
  },
});

export default Comments;
