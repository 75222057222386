import React from 'react';
import { View, StyleSheet } from 'react-native';
import { widthPercentageToDP as wp } from 'react-native-responsive-screen';
import { colourConst } from '../../globalStyles';

const Line = () => {
  return <View style={style.line}></View>;
};
const style = StyleSheet.create({
  line: {
    borderBottomWidth: 1,
    width: wp('95'),
    alignSelf: 'center',
    marginTop: 18,
    borderColor: colourConst.font,
  },
});

export default Line;
