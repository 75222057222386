import { Text, StyleSheet } from 'react-native';
import { colourConst, fontSize, fontFamilyConst } from '../../../globalStyles';
import { TextProps } from '../../../utils/types';

const TextTitleItem = (props: TextProps) => {
  return <Text style={styles.text}>{props.children}</Text>;
};

const styles = StyleSheet.create({
  text: {
    fontFamily: fontFamilyConst.bold,
    fontSize: fontSize.smallMedium,
    color: colourConst.font,
    textAlign: 'left',
  },
});
export default TextTitleItem;
