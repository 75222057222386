import { StyleSheet, View, Image } from 'react-native';
import TextInfoTitle from '../TextComponents/TextInfoTitle';

import React from 'react';
import TextInfoData from '../TextComponents/TextInfoData';
import { MiniInfoProps } from '../../utils/types';

const MiniInfo = ({ title, info, img }: MiniInfoProps) => {
  return (
    <View>
      <TextInfoTitle>{title}</TextInfoTitle>
      <View style={styles.container}>
        <Image source={img} style={styles.img} />
        <TextInfoData>{info}</TextInfoData>
      </View>
    </View>
  );
};

export default MiniInfo;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  img: {
    width: '25%',
    aspectRatio: 1,
    marginVertical: 8,
  },
});
