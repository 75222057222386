import React, { useEffect } from 'react';
import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs';
import HomeNavigation from './home';
import MoreNavigation from './more';
import NotificationsNavigation from './notifications';
import Login from './login';
import { useSecureContext } from './context';
import queryString from 'query-string';
import AgendaNavigation from './agenda';
import SocialCircleNavigation from './social-circle';
import { colourConst } from './globalStyles';
import MyEventIcon from './components/MyEventIcon';
import NotificationsIcon from './components/NotificationsIcon';
import MoreIcon from './components/MoreIcon';
import AgendaIcon from './components/AgendaIcon';
import SocialCircleIcon from './components/SocialCircleIcon';
import { useNotificationsContext } from './NotificationsContext';
import { useNotifications } from './hooks/useNotifications';
import { Easing } from 'react-native-reanimated';
const Tab = createMaterialBottomTabNavigator();

const RootNavigation = () => {
  const { initialized, state, token, setState } = useSecureContext();
  const { unreadNotifications, setUnreadNotifications } =
    useNotificationsContext();
  const { refetch, data } = useNotifications();

  useEffect(() => {
    document.title = 'Winners Circle';
    const parsed = queryString.parse(window.location.hash);
    const urlToken = parsed.access_token;
    const urlState = parsed.state;

    if (
      initialized &&
      typeof urlToken === 'string' &&
      typeof urlState === 'string' &&
      token !== urlToken &&
      urlState === state
    ) {
      setState((prev) => {
        if (!prev) {
          return {
            initialized: true,
            state: null,
            token: urlToken,
            contextTokens: null,
            locationAndWave: null,
            userData: null,
          };
        }

        return {
          ...prev,
          state: null,
          token: urlToken,
        };
      });
    }
  }, [initialized, state, token, setState]);
  useEffect(() => {
    if (data) {
      const numberOfUnreadNotifications = data.filter(
        (notification) => notification.NotificationStatusTypeId === 1
      ).length;
      setUnreadNotifications(numberOfUnreadNotifications);
    }
  }, [data, setUnreadNotifications, token, refetch]);

  if (!initialized) {
    return null;
  }

  return token ? (
    <>
      <Tab.Navigator
        initialRouteName="My Event"
        activeColor={colourConst.blue}
        inactiveColor={colourConst.inactiveGray}
        barStyle={{ backgroundColor: colourConst.white }}
        screenOptions={{}}
      >
        <Tab.Screen
          name="My Event"
          component={HomeNavigation}
          options={{
            tabBarLabel: 'My Event',
            tabBarIcon: ({ focused }) => <MyEventIcon focused={focused} />,
          }}
        />
        <Tab.Screen
          name="Agenda"
          component={AgendaNavigation}
          options={{
            tabBarLabel: 'Agenda',
            tabBarIcon: ({ focused }) => <AgendaIcon focused={focused} />,
          }}
        />
        <Tab.Screen
          name="Social Circle Tab"
          component={SocialCircleNavigation}
          options={{
            tabBarLabel: 'Social Circle',
            tabBarIcon: ({ focused }) => <SocialCircleIcon focused={focused} />,
          }}
        />
        <Tab.Screen
          name="Notifications"
          component={NotificationsNavigation}
          options={{
            //TODO: Show unread notifications badge
            tabBarBadge: unreadNotifications ? unreadNotifications : null,

            tabBarLabel: 'Notifications',
            tabBarIcon: ({ focused }) => (
              <NotificationsIcon focused={focused} />
            ),
          }}
        />
        <Tab.Screen
          name="More"
          component={MoreNavigation}
          options={{
            tabBarLabel: 'More',
            tabBarIcon: ({ focused }) => <MoreIcon focused={focused} />,
          }}
        />
      </Tab.Navigator>
    </>
  ) : (
    <Login />
  );
};

export default React.memo(RootNavigation);
