import React from 'react';
import { Text, StyleSheet } from 'react-native';
import {
  colourConst,
  fontSize,
  fontFamilyConst,
} from '../../../../globalStyles';
import { TextProps } from '../../../../utils/types';

const TextEveningActivity = (props: TextProps) => {
  if (!props.children) {
    return null;
  }

  let content = props.children;
  let infoString = '';
  const jsonStartIndex = content.indexOf('{');
  let location = '';
  if (jsonStartIndex !== -1) {
    location = content
      .substring(0, jsonStartIndex)
      .replace(/^[\s-]+|[\s-]+$/g, '');
    try {
      content = JSON.parse(content.substring(jsonStartIndex));
    } catch (error) {
      console.error('Error parsing JSON:', error);
      return null;
    }
  }

  if (content && typeof content === 'object') {
    const keysOrder = ['Table', 'Starter', 'Main'];
    const orderedContent: string[] = [];

    keysOrder.forEach((keyOrder) => {
      Object.keys(content).forEach((key) => {
        if (key.includes(keyOrder)) {
          orderedContent.push(`${keyOrder}: ${content[key]}`);
        }
      });
    });

    infoString = orderedContent.join(', ');
  } else {
    infoString = content;
  }

  return (
    <Text style={styles.text}>
      {location ? `${location}, ${infoString}` : infoString}
    </Text>
  );
};

const styles = StyleSheet.create({
  text: {
    fontFamily: fontFamilyConst.bold,
    fontSize: fontSize.smallRegular,
    color: colourConst.blue,
    textAlign: 'center',
    lineHeight: 14,
  },
});

export default TextEveningActivity;
