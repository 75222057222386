import { View, StyleSheet } from 'react-native';
import React from 'react';
import TextCardInfo from '../TextComponents/Home/TextCardInfo';
import { InfoActivityProps } from '../../utils/types';
import TextCardInfoTitle from '../TextComponents/Home/TextCardInfoTitle';

const EveningActivityItemInfo = ({ activityViewDetail }: InfoActivityProps) => {
  const processItem = (item: any) => {
    if (!item || !item.Name || !item.Content) {
      return null;
    }

    return (
      <View key={item?.Name}>
        <TextCardInfoTitle>{item?.Name}</TextCardInfoTitle>
        <TextCardInfo>{item?.Content}</TextCardInfo>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      {Array.isArray(activityViewDetail)
        ? activityViewDetail.map(processItem)
        : processItem(activityViewDetail)}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 25,
  },
});

export default EveningActivityItemInfo;
