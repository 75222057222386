export const useTourInfo = (
  activityViewDetail: { Name: string; Content: string }[]
) => {
  const description = activityViewDetail.find(
    (x) => x.Name === 'Description'
  )?.Content;
  const itinerary = activityViewDetail.find(
    (x) => x.Name === 'Itinerary'
  )?.Content;
  const additionalInfo = activityViewDetail.find(
    (x) => x.Name === 'Additional Information'
  )?.Content;
  const weather = activityViewDetail.find(
    (x) => x.Name === 'Detail if this tour could be affected by bad weather?'
  )?.Content;
  const location = activityViewDetail.find(
    (x) => x.Name === 'Location'
  )?.Content;
  return { description, itinerary, additionalInfo, weather, location };
};
