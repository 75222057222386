import { View, StyleSheet } from 'react-native';
import React from 'react';
import TextCardInfo from '../TextComponents/Home/TextCardInfo';
import { InfoActivityProps } from '../../utils/types';
import TextCardInfoTitle from '../TextComponents/Home/TextCardInfoTitle';
const AgendaItemInfo = ({ activityViewDetail }: InfoActivityProps) => {
  return (
    <View style={styles.container}>
      {activityViewDetail.map((item, i) => {
        return (
          <View key={i}>
            <TextCardInfoTitle>{item.Name}</TextCardInfoTitle>
            <TextCardInfo>{item.Content}</TextCardInfo>
          </View>
        );
      })}
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    marginTop: 25,
  },
});

export default AgendaItemInfo;
