import React from 'react';
import { Pressable, Image, StyleSheet } from 'react-native';
import TextMoreCard from '../TextComponents/TextMoreCard';
import { colourConst } from '../../globalStyles';
import { useWindowDimensions } from 'react-native';
import { InformationItemProps } from '../../utils/types';

const InformationItem = ({
  title,
  imageUrl,
  onPress,
}: InformationItemProps) => {
  const { width } = useWindowDimensions();
  const columnWidth = width / 2 - 20;
  return (
    <Pressable style={[styles.card, { width: columnWidth }]} onPress={onPress}>
      <Image style={styles.img} source={imageUrl} />
      <TextMoreCard>{title}</TextMoreCard>
    </Pressable>
  );
};
const styles = StyleSheet.create({
  card: {
    aspectRatio: 1.5,
    borderRadius: 10,
    backgroundColor: colourConst.white,
    borderColor: colourConst.inactiveGray,
    borderWidth: 0.5,
    marginTop: 15,
    maxWidth: 300,

    shadowOpacity: 0.25,
    shadowRadius: 5,
    shadowOffset: {
      height: 3,
      width: 0,
    },
    elevation: 5,
  },
  img: {
    aspectRatio: 1.75,
    height: '65%',
    margin: 3,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
});
export default InformationItem;
