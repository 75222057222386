import Constants from 'expo-constants';
import { ContextApi } from '../types';
const { NEXT_PUBLIC_PEOPLE_BASE_URL } = Constants.expoConfig!.extra!;
const contextsApiUrl = `${NEXT_PUBLIC_PEOPLE_BASE_URL}/contexts/contextapi`;

export const getTokens = (token: string) =>
  fetch(contextsApiUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json() as Promise<ContextApi>;
      }

      throw response;
    })
    .catch((error) => {
      console.error('Network error in getTokens:', error);
      throw error;
    });
