import React from 'react';
import {
  Pressable,
  View,
  StyleSheet,
  ImageBackground,
  ScrollView,
} from 'react-native';
import { Image } from 'expo-image';
import { colourConst } from '../../globalStyles';
import RainbowLine from '../RainbowLine';
import { useSetting } from './utils';
import ToggleButton from '../../components/ToggleButton';
import TextLogOut from '../TextComponents/TextLogOut';
import { useLogout } from '../../hooks/useLogout';
import Legal from '../Legal';
const Settings = () => {
  const {
    handleIsFahrenheit,
    isFahrenheit,
    animatedStyles,
    tap,
    policiTextStyle,
    policyTap,
    mockText,
  } = useSetting();
  const { handleLogout } = useLogout();
  return (
    <View style={styles.pageContainer}>
      <RainbowLine>
        <ImageBackground
          source={require('../../../assets/watermark.png')}
          style={styles.background}
        >
          <Image
            style={styles.img}
            source={require('../../../assets/moreImg/Settings.png')}
          />
          <ScrollView style={styles.main}>
            <View style={styles.container}>
              <View>
                {/* <ToggleButton
                condition={isCamera}
                handler={handleIsCamera}
                label={{ left: 'Off', right: 'On' }}
                text={'Allow to use the camera'}
                value={isCamera}
              /> */}
                <ToggleButton
                  handler={handleIsFahrenheit}
                  label={{ left: '°C', right: '°F' }}
                  text={'Temperature units'}
                  value={isFahrenheit}
                />
              </View>
              {/* <View>
              <Legal
                animatedStyles={animatedStyles}
                tap={tap}
                title={'Terms & Conditions'}
                text={mockText}
              />
              <Legal
                animatedStyles={policiTextStyle}
                tap={policyTap}
                title={'Privacy Policy'}
                text={mockText}
              />
            </View> */}
            </View>
          </ScrollView>
          <Pressable style={styles.button} onPress={handleLogout}>
            <TextLogOut>LOG OUT</TextLogOut>
          </Pressable>
        </ImageBackground>
      </RainbowLine>
    </View>
  );
};

export default Settings;
const styles = StyleSheet.create({
  pageContainer: {
    flex: 1,
    width: '100%',
    maxWidth: 600,
    justifyContent: 'center',
    alignSelf: 'center',
  },
  main: {
    flex: 1,
  },
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },

  button: {
    backgroundColor: colourConst.blue,
    borderRadius: 10,
    paddingHorizontal: 10,
    paddingVertical: 0,
    marginVertical: 15,
    width: '90%',
    alignSelf: 'center',
  },
  background: {
    flex: 1,
  },
  img: {
    width: '100%',
    aspectRatio: 1.75,
    marginBottom: 30,
  },
});
