import { People, AgendaView } from '../types';
import Constants from 'expo-constants';
import { LocationWithWaves } from '../types';
const { STRAPI_BASE_URL } = Constants.expoConfig!.extra!;

export const getInformationPages = (token: string, wave: string) => {
  // const upperCaseWave = wave.toUpperCase();
  const pagesUrl = `${STRAPI_BASE_URL}/api/pages?filters[waves][UUID][$eq]=${wave}&sort=Sorting_Index%3Aasc`;
  return fetch(pagesUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json() as Promise<People>;
    }

    throw response;
  });
};
