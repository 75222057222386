import { People, AgendaView, LocationWithMaybeWave } from '../types';
import Constants from 'expo-constants';
import { LocationWithWaves } from '../types';
const { NEXT_PUBLIC_PEOPLE_BASE_URL, NEXT_PUBLIC_BASE_URL } =
  Constants.expoConfig!.extra!;
const peopleUrl = `${NEXT_PUBLIC_PEOPLE_BASE_URL}/contexts`;

export const getPerson = (token: string) =>
  fetch(peopleUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json() as Promise<People>;
    }

    throw response;
  });

export const getAgenda = (
  token: string,
  locationAndWave?: LocationWithWaves | null | LocationWithMaybeWave
) =>
  getPerson(token)
    .then((response) => {
      const waveId = locationAndWave?.wave?.ProjectId;

      return fetch(
        `${NEXT_PUBLIC_BASE_URL}/participants/${response.ParticipantId}/agendaview?activityTimeslotProjectId=${waveId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    })
    .then((response) => {
      if (response.ok) {
        return response.json() as Promise<readonly AgendaView[]>;
      }

      throw response;
    });
