import { StyleSheet } from 'react-native';
import {
  colourConst,
  fontSize,
  fontFamilyConst,
} from '../../../../globalStyles';
import { TextHomeProps } from '../../../../utils/types';
import React from 'react';
import Animated, { withTiming, Easing } from 'react-native-reanimated';
import useTextAnimation from '../../../../hooks/useTextAnimation';

const TextUser = (props: TextHomeProps) => {
  const { textOpacity, animatedTextStyle } = useTextAnimation();
  return (
    <Animated.Text
      style={[styles.text, animatedTextStyle]}
      onLayout={() => {
        textOpacity.value = withTiming(1, {
          duration: 500,
          easing: Easing.linear,
        });
      }}
    >
      {props.children}
    </Animated.Text>
  );
};

const styles = StyleSheet.create({
  text: {
    fontFamily: fontFamilyConst.bold,
    fontSize: fontSize.mediumLarge,
    color: colourConst.font,
    textAlign: 'center',
    marginBottom: 10,
  },
});
export default TextUser;
