import { useSharedValue, useAnimatedStyle } from 'react-native-reanimated';

const useTextAnimation = () => {
  const textOpacity = useSharedValue(0);
  const animatedTextStyle = useAnimatedStyle(() => {
    return {
      opacity: textOpacity.value,
    };
  });
  return { textOpacity, animatedTextStyle };
};

export default useTextAnimation;
