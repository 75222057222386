import React, { useState, useEffect } from 'react';
import { createPost } from '../../helpers/get-social-posts';
import {
  View,
  Image,
  TextInput,
  StyleSheet,
  TouchableOpacity,
  Text,
  SafeAreaView,
  ActivityIndicator,
} from 'react-native';
import * as ImageManipulator from 'expo-image-manipulator';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import * as ImagePicker from 'expo-image-picker';
import Constants from 'expo-constants';
import { v4 as uuid } from 'uuid';
import { useSecureContext } from '../../context';
import { colourConst, fontFamilyConst } from '../../globalStyles';
import { Video, ResizeMode } from 'expo-av';

const { NEXT_PUBLIC_ASSETS_BASE_URL } = Constants.expoConfig!.extra!;
type RootStackParamList = {
  YourScreenName: undefined;
};

type CreatePostNavigationProp = StackNavigationProp<
  RootStackParamList,
  'YourScreenName'
>;

const CreatePost = () => {
  const postId = uuid();
  const [inputText, setInputText] = useState('');
  const { token, locationAndWave, userData, strapiToken } = useSecureContext();
  const givenName = userData?.FirstName;
  const familyName = userData?.LastName;
  const participantId = userData?.ParticipantId;
  const [image, setImage] = useState('');
  const [video, setVideo] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });
  const navigation = useNavigation<CreatePostNavigationProp>();
  const [videoUrl, setVideoUrl] = useState<string | null>(null);

  useEffect(() => {
    if (video) {
      fetch(video)
        .then((res) => res.blob())
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);
          setVideoUrl(blobUrl);
        });
    }
  }, [video]);

  const showMessage = (type: 'success' | 'error', text: string) => {
    setMessage({ type, text });
    setTimeout(() => {
      setMessage({ type: '', text: '' });
      if (type === 'success') {
        navigation.popToTop();
      }
    }, 3000);
  };

  const pickMedia = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (!result.cancelled && result.assets && result.assets.length > 0) {
      const selectedAsset = result.assets[0];
      const fileUri = selectedAsset.uri;

      if (fileUri.startsWith('data:image/')) {
        setImage(fileUri);
        setVideo('');
      } else if (fileUri.startsWith('data:video/')) {
        setVideo(fileUri);
        setImage('');
      }
    }
  };

  const handleCreatePost = async () => {
    // User data validation
    if (!strapiToken || !givenName || !familyName || !participantId) {
      showMessage('error', 'User data is not available.');
      return;
    }

    // Other validations
    if (
      !locationAndWave ||
      !locationAndWave.wave ||
      !locationAndWave.wave.ProjectId
    ) {
      showMessage('error', 'ProjectId is not available.');
      return;
    }

    const projectId = locationAndWave.wave.ProjectId as string;
    setLoading(true);

    try {
      let fileUri = image || video;
      const fileType = image ? 'image' : 'video';
      const fileExtension = fileType === 'image' ? 'jpg' : 'mp4'; // Adjust based on actual file format
      const fileName = `${fileType}_${postId}.${fileExtension}`;
      const mimeType = fileType === 'image' ? 'image/jpeg' : 'video/mp4'; // Adjust based on actual MIME type

      // Manipulate fileUri if it's an image
      if (fileType === 'image' && fileUri) {
        const compressedImage = await ImageManipulator.manipulateAsync(
          fileUri,
          [{ resize: { width: 800 } }],
          { compress: 0.9, base64: true }
        );
        fileUri = compressedImage.base64;
      } else if (fileType === 'video' && fileUri) {
        // Remove the data:video/mp4;base64, part from the video base64 string
        fileUri = fileUri.split(',')[1];
      }

      // Construct postUrl
      const postUrl = `${NEXT_PUBLIC_ASSETS_BASE_URL}/digital-assets/binaries/${
        fileType === 'image' ? '1' : '2'
      }/${postId}`;

      // Prepare the JSON payload
      const payload = {
        id: postId,
        fileName: fileName,
        extension: fileExtension,
        mimeType: mimeType,
        binary: fileUri,
      };

      // Send the POST request with the JSON payload
      const response = await fetch(postUrl, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          ETag: '0',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const imageUrl = await response.json();

      // Make a second POST request to createPost
      const postData = await createPost(
        strapiToken,
        projectId,
        imageUrl,
        inputText,
        givenName,
        familyName,
        participantId,
        fileType
      );

      showMessage('success', 'Post created successfully');
    } catch (error) {
      console.error('Error:', error);
      showMessage(
        'error',
        'There was an error creating your post. Please try again.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.content}>
        <TextInput
          style={styles.textInput}
          placeholder="Tell us what's happening"
          onChangeText={(text) => setInputText(text)}
          value={inputText}
          multiline
        />
        <View style={styles.buttonContainer}>
          <TouchableOpacity style={styles.button} onPress={pickMedia}>
            <Text style={styles.buttonText}>Pick an image or video</Text>
            <Text style={styles.maxSize}>(Max size: 25MB)</Text>
          </TouchableOpacity>
        </View>
        {!!image && (
          <Image source={{ uri: image }} style={styles.previewImage} />
        )}

        {videoUrl && (
          <video
            controls
            preload="metadata"
            playsInline
            muted
            style={{ width: 300, height: 300 }}
          >
            <source src={`${videoUrl}#t=0.001`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
        <TouchableOpacity
          style={[
            styles.button,
            styles.createPostButton,
            !(image || video) && styles.createPostButtonDisabled,
          ]}
          onPress={handleCreatePost}
          disabled={!(image || video)}
        >
          {loading ? (
            <ActivityIndicator size="small" color="#fff" />
          ) : (
            <Text style={styles.buttonText}>Post</Text>
          )}
        </TouchableOpacity>
      </View>
      {message.text !== '' && (
        <View
          style={[
            styles.message,
            message.type === 'error'
              ? styles.errorMessage
              : styles.successMessage,
          ]}
        >
          <Text style={styles.messageText}>{message.text}</Text>
        </View>
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colourConst.background,
  },
  header: {
    backgroundColor: '#1C60C3',
    paddingVertical: 16,
    paddingHorizontal: 16,
  },
  headerText: {
    color: '#fff',
    fontSize: 20,
    fontWeight: 'bold',
  },
  content: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    paddingTop: 30,
    paddingBottom: 20,
  },
  textInput: {
    width: '100%',
    height: 100,
    paddingHorizontal: 12,
    paddingVertical: 8,
    fontSize: 16,
    backgroundColor: colourConst.background,
    fontFamily: fontFamilyConst.regular,
    color: colourConst.font,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    marginBottom: 10,
  },
  button: {
    backgroundColor: colourConst.blue,
    borderRadius: 5,
    paddingHorizontal: 8,
    paddingVertical: 12,
    width: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 5,
  },
  buttonText: {
    fontFamily: fontFamilyConst.regular,
    color: '#fff',
    fontSize: 12,
    fontWeight: 'bold',
    paddingBottom: 2,
  },
  maxSize: {
    fontFamily: fontFamilyConst.regular,
    color: '#fff',
    fontSize: 9,
  },
  previewImage: {
    width: 320,
    height: 280,
    resizeMode: 'contain',
    marginBottom: 20,
  },
  createPostButton: {
    backgroundColor: colourConst.green,
  },
  message: {
    padding: 10,
    borderRadius: 4,
    marginTop: 20,
    width: '80%',
    alignSelf: 'center',
  },
  successMessage: {
    backgroundColor: '#4caf50',
  },
  errorMessage: {
    backgroundColor: '#f44336',
  },
  messageText: {
    color: '#fff',
    textAlign: 'center',
  },
  createPostButtonDisabled: {
    backgroundColor: colourConst.inactiveGray,
  },
});

export default CreatePost;
