import React, { useEffect, useState } from 'react';
import {
  View,
  StyleSheet,
  FlatList,
  Modal,
  SafeAreaView,
  Text,
  TouchableOpacity,
} from 'react-native';
import Swiper from 'react-native-web-swiper';
import { useSecureContext } from '../../context';
import { getPhotoIds } from '../../helpers/photos';
import { colourConst, fontFamilyConst } from '../../globalStyles';
import ImageItem from '../ImageItem';

const PhotoGallery: React.FC<PhotoGalleryProps> = ({ route }) => {
  const [mediasIds, setMediasIds] = useState<string[]>([]);
  const [selectedImage, setSelectedImage] = useState<number | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const categoryName = route.params?.name;
  const categoryId = route.params?.id;
  const { token, locationAndWave } = useSecureContext();
  const waveId = locationAndWave?.wave?.ProjectId;

  useEffect(() => {
    getPhotoIds(token, categoryId, waveId).then((data) => {
      const ids = data.map((photoId) => photoId.Id);
      setMediasIds(ids);
    });
  }, []);
  console.log('mediasIds: ', mediasIds);
  const handleImagePress = (index: number) => {
    setSelectedImage(index);
    setModalVisible(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setModalVisible(false);
  };

  const renderImageItem = ({ item, index }) => (
    <ImageItem imageId={item} onPress={() => handleImagePress(index)} />
  );

  return (
    <View style={styles.container}>
      <FlatList
        data={mediasIds}
        renderItem={renderImageItem}
        keyExtractor={(item, index) => index.toString()}
        numColumns={2}
        contentContainerStyle={styles.listContainer}
        ListEmptyComponent={
          <Text style={styles.noPhotosText}>
            A selection of the photos from your event will be posted here each
            day.
          </Text>
        }
      />
      <Modal
        animationType="slide"
        transparent={false}
        visible={modalVisible}
        onRequestClose={closeModal}
      >
        <SafeAreaView style={styles.modalContainer}>
          <TouchableOpacity style={styles.closeButton} onPress={closeModal}>
            <Text style={styles.closeButtonText}>Close</Text>
          </TouchableOpacity>
          <Swiper
            from={selectedImage || 0}
            loop={false}
            showsPagination={false}
            showsButtons={true}
            containerStyle={styles.swiperContainer}
          >
            {mediasIds.map((id, index) => (
              <View key={index} style={styles.slide}>
                <ImageItem imageId={id} onPress={() => {}} />
              </View>
            ))}
          </Swiper>
        </SafeAreaView>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  listContainer: {
    paddingHorizontal: 16, // Adjust the horizontal padding
    paddingVertical: 16,
  },
  imageContainer: {
    flex: 1,
    margin: 4,
    borderRadius: 4,
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    height: '100%',
  },
  loader: {
    width: 100,
    height: 100,
    alignSelf: 'center',
    resizeMode: 'contain',
  },
  noPhotosText: {
    fontSize: 16,
    textAlign: 'center',
    fontFamily: fontFamilyConst.regular,
    color: colourConst.font,
    marginTop: 16,
  },
  modalContainer: {
    flex: 1,
    backgroundColor: 'black',
  },
  closeButton: {
    position: 'absolute',
    top: 60,
    right: 20,
    zIndex: 1,
  },
  closeButtonText: {
    color: 'white',
    fontSize: 18,
  },
  modalImage: {
    width: '100%',
    height: '100%',
  },
  placeholder: {
    width: '100%',
    height: '100%',
    backgroundColor: '#e0e0e0',
  },
  swiperContainer: {
    flex: 1,
  },
  slide: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
});

export default PhotoGallery;
