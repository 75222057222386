import { StyleSheet, View, FlatList } from 'react-native';
import { useWeather } from './utils';
import React from 'react';
import TextDateWeather from '../TextComponents/Home/TextDateWeather';
import TextLocationWeather from '../TextComponents/Home/TextLocationWeather';
import WeatherCard from '../WeatherCard';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
  Easing,
} from 'react-native-reanimated';
interface WeatherProps {
  location?: string;
}

const Weather = ({ location }: WeatherProps) => {
  const {
    cityCounty,
    getDateToDisplay,
    weatherDataList,
    timeForecast,
    getTemperature,
    isLoading,
  } = useWeather(location);
  const opacityWeatherAnimated = useSharedValue(0);
  const animatedViewStyle = useAnimatedStyle(() => ({
    opacity: opacityWeatherAnimated.value,
  }));
  return (
    <>
      {isLoading ? (
        <View style={styles.placeholder}></View>
      ) : (
        <Animated.View
          style={[styles.component, animatedViewStyle]}
          onLayout={() => {
            opacityWeatherAnimated.value = withTiming(1, {
              duration: 300,
              easing: Easing.linear,
            });
          }}
        >
          <TextDateWeather>{getDateToDisplay()}</TextDateWeather>
          <TextLocationWeather>{cityCounty}</TextLocationWeather>
          {weatherDataList && weatherDataList.length > 0 && (
            <FlatList
              style={styles.list}
              showsHorizontalScrollIndicator={false}
              contentContainerStyle={styles.cardsContainer}
              horizontal={true}
              data={weatherDataList}
              renderItem={({ item, index }) => (
                <WeatherCard
                  imgCode={item.weather?.[0]?.icon}
                  time={timeForecast(item.dt)}
                  temperature={getTemperature(item.main.temp)}
                  index={index}
                />
              )}
            />
          )}
        </Animated.View>
      )}
    </>
  );
};

export default Weather;

const styles = StyleSheet.create({
  component: {
    marginTop: 20,
  },
  list: {
    marginVertical: 10,
    flex: 1,
    width: '100%',
  },
  cardsContainer: {
    flex: 1,
  },
  placeholder: {
    height: 200,
  },
});
