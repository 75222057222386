export const useWeatherCard = (imgCode: string) => {
  let imgIcon:any;
  switch (imgCode) {
    case '01d':
      imgIcon = 'weather-sunny';
      break;
    case '01n':
      imgIcon = 'weather-night';
      break;
    case '02d':
      imgIcon = 'weather-partly-cloudy';
      break;
    case '02n':
      imgIcon = 'weather-night-partly-cloudy';
      break;
    case '03d':
      imgIcon = 'weather-cloudy';
      break;
    case '03n':
      imgIcon = 'weather-night-partly-cloudy';
      break;
    case '04d':
      imgIcon = 'weather-cloudy';
      break;
    case '04n':
      imgIcon = 'weather-night-partly-cloudy';
      break;
    case '09d':
      imgIcon = 'weather-pouring';
      break;
    case '09n':
      imgIcon = 'weather-pouring';
      break;
    case '10d':
      imgIcon = 'weather-pouring';
      break;
    case '10n':
      imgIcon = 'weather-pouring';
      break;
    case '11d':
      imgIcon = 'weather-lightning';
      break;
    case '11n':
      imgIcon = 'weather-lightning';
      break;
    case '13d':
      imgIcon = 'weather-partly-snowy';
      break;
    case '13n':
      imgIcon = 'weather-snowy';
      break;
    case '50d':
      imgIcon = 'weather-fog';
      break;
    case '50n':
      imgIcon = 'weather-fog';
      break;
    default:
      imgIcon = 'weather-sunny';
      break;
  }
  return { imgIcon };
};
