import { StyleSheet, View } from 'react-native';
import React from 'react';
import AgendaLoader from '../AgendaLoader';
import TimeSlotAgenda from '../TimeSlotAgenda';
import DayTab from '../DayTab';
import { getAgenda } from '../../helpers/get-agenda';
import { useTabAgendaLoader } from './utils';
import { widthPercentageToDP as wp } from 'react-native-responsive-screen';
import { layoutAgenda } from '../../globalStyles';
import { getDayMonth } from '../../helpers/date-time';

const TabAgendaLoader = () => {
  const { activeTabIndex, indexTabHandler, getUiDates } = useTabAgendaLoader();
  return (
    <>
      <AgendaLoader
        queryKey="agenda"
        queryFunction={getAgenda}
        filterData={(data) => {
          const uniqueDates = getUiDates(data);
          return data.filter(
            (item) =>
              getDayMonth(item.DateTimeBegin) === uniqueDates[activeTabIndex]
          );
        }}
        renderItem={({ item: timeslot }) => {
          return (
            <TimeSlotAgenda
              key={timeslot.ActivityId}
              activityType={timeslot.ActivityTypeName}
              bookingStatus={timeslot.BookingTypeName}
              activityName={timeslot.ActivityName}
              timeBegin={timeslot.DateTimeBegin}
              timeEnd={timeslot.DateTimeEnd}
              activeTab={activeTabIndex}
              agendaViewDetail={timeslot.AgendaViewDetail}
              imageUrl={timeslot.ActivityImageUrlMobile}
            />
          );
        }}
        renderHeader={(data) => {
          const uniqueDates = getUiDates(data);
          return (
            <>
              <View style={style.tabs}>
                {data.length > 0 &&
                  uniqueDates.map((item, index) => (
                    <DayTab
                      key={item}
                      index={index}
                      timeBegin={item}
                      isActive={index === activeTabIndex}
                      onPress={() => indexTabHandler(index)}
                      totalDays={uniqueDates.length}
                    />
                  ))}
              </View>
            </>
          );
        }}
      />
    </>
  );
};
const style = StyleSheet.create({
  tabs: {
    flexDirection: 'row',
    marginHorizontal: layoutAgenda.margin,
    justifyContent: 'center',
    gap: 3,
    marginTop: wp('3%'),
  },
});

export default TabAgendaLoader;
