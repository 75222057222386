import React from 'react';
import { View, FlatList, StyleSheet, Text, Image } from 'react-native';
import { colourConst, fontFamilyConst } from '../../globalStyles';
import { useQuery } from 'react-query';
import { getPhotoCategories } from '../../helpers/photos';
import { useSecureContext } from '../../context';
import PhotoCategory from '../PhotoCategory';
const PhotosScreen = () => {
  const { token, locationAndWave } = useSecureContext();
  const waveId = locationAndWave?.wave?.ProjectId;
  const { isLoading, isError, data, error } = useQuery('photos', () =>
    getPhotoCategories(token, waveId)
  );
  if (isLoading) {
    return (
      <Image
        style={styles.loader}
        source={require('../../../assets/loader.gif')}
      />
    );
  }
  console.log('data photo screen', data);
  if (isError) {
    return <Text>Something went wrong {error?.message}</Text>;
  }

  if (data) {
    const categoriesWithPhotos = data
      ?.filter(
        (category) => category.Count !== 0 && category.IsHidden === false
      )
      .sort((a, b) => a.Order - b.Order);
    if (categoriesWithPhotos.length === 0) {
      return (
        <View style={styles.noPhotosTextContainer}>
          <Text style={styles.noPhotosText}>
            A selection of the photos from your event will be posted here each
            day.
          </Text>
        </View>
      );
    }

    return (
      <FlatList
        data={categoriesWithPhotos}
        renderItem={({ item }) => (
          <PhotoCategory
            name={item.Name}
            backgroundImageId={item.CategoryImageryId}
            categoryId={item.Id}
          />
        )}
        numColumns={2}
        contentContainerStyle={styles.container}
        keyExtractor={(item) => item.Id}
        columnWrapperStyle={styles.row}
      />
    );
  }
};
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 16,
    paddingHorizontal: 4,
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  loader: {
    flex: 1,
    width: 100,
    height: 100,
    alignSelf: 'center',
    resizeMode: 'contain',
  },
  noPhotosText: {
    fontSize: 16,
    textAlign: 'center',
    fontFamily: fontFamilyConst.regular,
    color: colourConst.font,
  },
  noPhotosTextContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
  },
});

export default PhotosScreen;
