import { useEffect } from 'react';
import { Pressable, PressableProps, View } from 'react-native';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
  Easing,
  interpolateColor,
} from 'react-native-reanimated';
import { colourConst } from '../../globalStyles';

export const useStyleTab = (
  totalDays: number,
  timeBegin: string,
  isActive: boolean
): {
  select: 'dateSelected' | 'dateSelectedSlim';
  notSelect: 'dateNotSelected' | 'dateNotSelectedSlim';
  day?: string;
  month?: string;
  weekday?: string;
  AnimatedPressable: React.FunctionComponent<
    Animated.AnimateProps<PressableProps & React.RefAttributes<View>>
  >;
  animatedStyle: {
    transform: {
      scale: 1 | 1.08;
    }[];
    backgroundColor: string;
    borderBottomColor: string;
    borderTopColor: string;
    opacity: number;
  };
  opacityAnimated: Animated.SharedValue<number>;
} => {
  let select: 'dateSelected' | 'dateSelectedSlim' = 'dateSelected';
  let notSelect: 'dateNotSelected' | 'dateNotSelectedSlim' = 'dateNotSelected';

  if (totalDays > 2) {
    select = 'dateSelectedSlim';
    notSelect = 'dateNotSelectedSlim';
  } else if (totalDays <= 3) {
    select = 'dateSelected';
    notSelect = 'dateNotSelected';
  }
  const [weekday, day, month] = timeBegin.split(' ');
  ////
  const AnimatedPressable = Animated.createAnimatedComponent(Pressable);
  const colorAnimated = useSharedValue(0);
  const opacityAnimated = useSharedValue(0);
  const animatedStyle = useAnimatedStyle(() => ({
    transform: [
      {
        scale: isActive
          ? withTiming(1.08, { duration: 200 })
          : withTiming(1, { duration: 200 }),
      },
    ],
    backgroundColor: interpolateColor(
      colorAnimated.value,
      [0, 1],
      [colourConst.dateTabUnselected, colourConst.white]
    ),
    borderBottomColor: interpolateColor(
      colorAnimated.value,
      [0, 1],
      [colourConst.dateTabUnselected, colourConst.blue]
    ),
    borderTopColor: interpolateColor(
      colorAnimated.value,
      [0, 1],
      [colourConst.dateTabUnselected, colourConst.blue]
    ),
    opacity: opacityAnimated.value,
    overflow: 'visible',
  }));
  useEffect(() => {
    colorAnimated.value = isActive
      ? withTiming(1, { duration: 300, easing: Easing.linear })
      : withTiming(0, { duration: 300, easing: Easing.linear });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  return {
    select,
    notSelect,
    weekday,
    day,
    month,
    AnimatedPressable,
    animatedStyle,
    opacityAnimated,
  };
};
