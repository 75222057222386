import Constants from 'expo-constants';
import { StrapiTokenResponse } from '../types';
const { STRAPI_BASE_URL } = Constants.expoConfig!.extra!;

export const getStrapiToken = (token: string) =>{
    
    const strapiTokenUrl = `${STRAPI_BASE_URL}/api/auth/auth0/callback?access_token=${token}`;
    return fetch(strapiTokenUrl)
    .then((response) => {
      if (response.ok) {
        return response.json() as Promise<StrapiTokenResponse>;
      }

      throw response;
    })
    .catch((error) => {
      console.error('Network error in getTokens:', error);
      throw error;
    });
}