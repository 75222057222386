import { StyleSheet, View } from 'react-native';
import React from 'react';
import { colourConst } from '../../globalStyles';
import TextWeatherCard from '../TextComponents/Home/TextWeatherCard';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import { useWeatherCard } from './utils';
interface WeatherCardProps {
  imgCode: string;
  time: string;
  temperature: string;
  index: number;
}

const WeatherCard = ({
  imgCode,
  time,
  temperature,
  index,
}: WeatherCardProps) => {
  const { imgIcon } = useWeatherCard(imgCode);
  return (
    <View style={styles.container}>
      <TextWeatherCard>{index === 0 ? 'NOW' : time}</TextWeatherCard>
      <MaterialCommunityIcons
        name={imgIcon}
        size={40}
        color={colourConst.blue}
        style={styles.icons}
      />
      <TextWeatherCard>{temperature}</TextWeatherCard>
    </View>
  );
};

export default WeatherCard;

const styles = StyleSheet.create({
  container: {
    width: 80,
    aspectRatio: 0.667,
    borderRadius: 10,
    borderWidth: 2,
    backgroundColor: colourConst.white,
    borderColor: colourConst.font,
    marginHorizontal: 14,
    paddingVertical: 5,
    justifyContent: 'space-between',
  },
  icons: {
    alignSelf: 'center',
  },
});
