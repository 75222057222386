import React from 'react';
import { StyleSheet } from 'react-native';
import { DayTabProps } from '../../utils/types';
import TextDayTabDate from '../TextComponents/TextDayTabDate';
import TextDayTabWeek from '../TextComponents/TextDayTabWeek';
import { useStyleTab } from './utils';
import { layoutAgenda } from '../../globalStyles';
import { withTiming, Easing } from 'react-native-reanimated';

const DayTab = ({
  // index,
  timeBegin,
  isActive,
  totalDays,
  onPress,
}: DayTabProps) => {
  const {
    day,
    month,
    weekday,
    AnimatedPressable,
    animatedStyle,
    opacityAnimated,
  } = useStyleTab(totalDays, timeBegin, isActive);

  return (
    <AnimatedPressable
      testID="container"
      style={[styles.base, animatedStyle]}
      onPress={onPress}
      onLayout={() => {
        opacityAnimated.value = withTiming(1, {
          duration: 300,
          easing: Easing.linear,
        });
      }}
    >
      <TextDayTabWeek isActive={isActive}>{weekday}</TextDayTabWeek>
      <TextDayTabDate
        isActive={isActive}
        totalDays={totalDays}
      >{`${month} ${day}`}</TextDayTabDate>
    </AnimatedPressable>
  );
};

const styles = StyleSheet.create({
  base: {
    flex: 1,
    borderRadius: layoutAgenda.radiusTab,
    paddingTop: layoutAgenda.paddingTabs,
    paddingBottom: layoutAgenda.paddingTabs,
    paddingLeft: 15,
    maxWidth: 100,
    borderTopWidth: 4,
    borderBottomWidth: 4,
  },
});

export default DayTab;
