import { Text, StyleSheet } from 'react-native';
import {
  colourConst,
  fontSize,
  fontFamilyConst,
} from '../../../../globalStyles';
import { TextHomeProps } from '../../../../utils/types';
import React from 'react';
import useTextAnimation from '../../../../hooks/useTextAnimation';
import Animated, {
  withTiming,
  Easing,
} from 'react-native-reanimated';

const TextIntro = (props: TextHomeProps) => {
  const { textOpacity, animatedTextStyle } = useTextAnimation();
  return (
    <Animated.Text
      style={[styles.text, animatedTextStyle]}
      onLayout={() => {
        textOpacity.value = withTiming(1, {
          duration: 500,
          easing: Easing.linear,
        });
      }}
    >
      {props.children}
    </Animated.Text>
  );
};

const styles = StyleSheet.create({
  text: {
    fontFamily: fontFamilyConst.bold,
    fontSize: fontSize.smallMedium,
    color: colourConst.font,
    textAlign: 'center',
    lineHeight: 22,
  },
});
export default TextIntro;
