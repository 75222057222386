import { StyleSheet } from 'react-native';
import { colourConst, fontSize, fontFamilyConst } from '../../../globalStyles';
import { TextDayTabWeekProps } from '../../../utils/types';
import React, { useEffect } from 'react';
import Animated, {
  Easing,
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

export const TextDayTabWeek = (props: TextDayTabWeekProps) => {
  const animation = useSharedValue(0);

  useEffect(() => {
    animation.value = props.isActive
      ? withTiming(1, { duration: 200, easing: Easing.linear })
      : withTiming(0, { duration: 200, easing: Easing.linear });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isActive]);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      color: interpolateColor(
        animation.value,
        [0, 1],
        [colourConst.unselectedTabDayText, colourConst.font]
      ),
    };
  });

  return (
    <Animated.Text style={[styles.base, animatedStyle]}>
      {props.children}
    </Animated.Text>
  );
};

const styles = StyleSheet.create({
  base: {
    fontFamily: fontFamilyConst.bold,
    fontSize: fontSize.medium,
    textAlign: 'left',
  },
});
export default TextDayTabWeek;
