import getBaseUrl from '../../helpers/getBaseUrl';
import { useContext } from 'react';
import {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
} from 'react-native-reanimated';
import { Gesture } from 'react-native-gesture-handler';
import { SettingsContext } from '../../../App';

export const useSetting = () => {
  const urlString = window.location.href;
  const baseUrl = getBaseUrl(urlString);

  const isTermsExpanded = useSharedValue(false);
  const animatedStyles = useAnimatedStyle(() => {
    return {
      height: withTiming(isTermsExpanded.value ? 200 : 0),
    };
  });
  const tap = Gesture.Tap().onEnd(() => {
    isTermsExpanded.value = !isTermsExpanded.value;
  });
  const isPolicyExpanded = useSharedValue(false);
  const policiTextStyle = useAnimatedStyle(() => {
    return {
      height: withTiming(isPolicyExpanded.value ? 200 : 0),
    };
  });
  const policyTap = Gesture.Tap().onEnd(() => {
    isPolicyExpanded.value = !isPolicyExpanded.value;
  });
  const { isFahrenheit, handleIsFahrenheit } = useContext(SettingsContext);
  const mockText =
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias repellat voluptas debitis nostrum minus fuga tempore ab, consequatur porro dolor vel vitae! Illo asperiores optio quas vel enim delectus suscipit.';
  return {
    isFahrenheit,
    handleIsFahrenheit,
    animatedStyles,
    tap,
    policiTextStyle,
    policyTap,
    mockText,
  };
};
