import { Text, StyleSheet } from 'react-native';
import {
  colourConst,
  fontSize,
  fontFamilyConst,
} from '../../../../globalStyles';
import { TextProps } from '../../../../utils/types';
import React from 'react';

const TextCardInfoTitle = (props: TextProps) => {
  return <Text style={styles.text}>{props.children}</Text>;
};

const styles = StyleSheet.create({
  text: {
    fontFamily: fontFamilyConst.bold,
    fontSize: fontSize.medium,
    color: colourConst.blue,
    textAlign: 'left',
    marginLeft: 21,
    marginBottom: 10,
  },
});
export default TextCardInfoTitle;
