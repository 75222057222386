import React, { useEffect, useState } from 'react';
import { View, Image, StyleSheet, TouchableOpacity, Text } from 'react-native';
import Constants from 'expo-constants';
import { useSecureContext } from '../../context';

const { NEXT_PUBLIC_ASSETS_BASE_URL } = Constants.expoConfig!.extra!;

interface ImageItemProps {
  imageId: string;
  onPress: () => void;
}

const ImageItem: React.FC<ImageItemProps> = ({ imageId, onPress }) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  console.log('imageUrl: ', imageUrl);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { token } = useSecureContext();

  useEffect(() => {
    fetchImageUrl();
  }, []);

  const fetchImageUrl = async () => {
    try {
      const response = await fetch(
        `${NEXT_PUBLIC_ASSETS_BASE_URL}/digital-assets/urls/view/2?idsGuidsCsv=${imageId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      setImageUrl(data[0]?.Url || null);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching image URL:', error);
      setIsLoading(false);
    }
  };

  return (
    <TouchableOpacity style={styles.imageContainer} onPress={onPress}>
      {isLoading ? (
        <View style={styles.placeholder} />
      ) : imageUrl ? (
        <Image
          source={{ uri: imageUrl }}
          style={styles.image}
          resizeMode="contain"
        />
      ) : (
        <View style={styles.placeholder} />
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  imageContainer: {
    width: 150, // Set a fixed width for the image container
    height: 150, // Set a fixed height for the image container
    margin: 8,
    borderRadius: 4,
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    height: '100%',
  },
  placeholder: {
    width: '100%',
    height: '100%',
    backgroundColor: '#e0e0e0',
  },
});
export default ImageItem;
