import { Notification } from '../types';
export const sortNotifications = (notifications: Notification[]) => {
  const sortedByDate = notifications.sort((a, b) => {
    const aSentAtUtc = new Date(a.SentAtUtc);
    const bSentAtUtc = new Date(b.SentAtUtc);
    return bSentAtUtc.getTime() - aSentAtUtc.getTime();
  });
  const sortedNotifications = sortedByDate.sort((a, b) => {
    // Sort by CanMarkAsRead (false comes before true)
    if (
      a.NotificationPriorityTypeId === 4 &&
      !(b.NotificationPriorityTypeId === 4)
    ) {
      return -1;
    } else if (
      !(a.NotificationPriorityTypeId === 4) &&
      b.NotificationPriorityTypeId === 4
    ) {
      return 1;
    } else {
      return 0;
    }
  });
  return sortedNotifications;
};
//Filter out all notifications but read and unread
export const filterNotifications = (notifications: Notification[]) => {
  return notifications
    .filter(
      (notification) =>
        notification.NotificationStatusTypeId === 1 ||
        notification.NotificationStatusTypeId === 3
    )
    .sort((a, b) => {
      const aSentAtUtc = new Date(a.SentAtUtc);
      const bSentAtUtc = new Date(b.SentAtUtc);
      return bSentAtUtc.getTime() - aSentAtUtc.getTime();
    });
};
