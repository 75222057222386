import React from 'react';
import { Text, StyleSheet } from 'react-native';
import {
  colourConst,
  fontSize,
  fontFamilyConst,
} from '../../../../globalStyles';
import { TextProps } from '../../../../utils/types';

const TextCardTitle = (props: TextProps) => {
  return <Text style={styles.text}>{props.children}</Text>;
};

const styles = StyleSheet.create({
  text: {
    fontFamily: fontFamilyConst.bold,
    fontSize: fontSize.mediumLarge,
    color: colourConst.blue,
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 5,
  },
});
export default TextCardTitle;
