import { useState } from 'react';
import { AgendaView } from '../../types';
import { format, parseISO } from 'date-fns';
import { getDayMonth } from '../../helpers/date-time';

export const useTabAgendaLoader = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const indexTabHandler = (index: number) => setActiveTabIndex(index);

  const getUniqueDates = (copyUserBookings: readonly AgendaView[]) => [
    ...new Set(
      copyUserBookings.map((item) => formatDateFns(item.DateTimeBegin))
    ),
  ];
  const formatDateFns = (dateString: string) => {
    const date = parseISO(dateString);

    return format(date, 'EEE MMM d yyyy');
  };

  const getUiDates = (data: readonly AgendaView[]) => {
    const arrayDays: any = [];
    data.map((item) => arrayDays.push(getDayMonth(item.DateTimeBegin)));
    const ud: string[] = Array.from(new Set(arrayDays));
    return ud;
  };

  return {
    getUniqueDates,
    activeTabIndex,
    indexTabHandler,
    getUiDates,
  };
};
