import { Text, StyleSheet } from 'react-native';
import { TextProps } from '../../../utils/types';
import { fontSize, fontFamilyConst, colourConst } from '../../../globalStyles';

const TextLogOut = ({ children }: TextProps) => {
  return <Text style={style.text}>{children}</Text>;
};
const style = StyleSheet.create({
  text: {
    fontFamily: fontFamilyConst.bold,
    fontSize: fontSize.smallRegular,
    color: colourConst.white,
    textAlign: 'center',
    textAlignVertical: 'center',
    marginVertical: 15,
  },
});
export default TextLogOut;
