import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import { useSecureContext } from '../context';
import { constructAuthorizeUrl } from './utils';

export const useAuthorize = (userType: string) => {
  const { setState } = useSecureContext();

  return useCallback(async () => {
    const state = uuid();

    await setState((prev) => {
      if (!prev) {
        return {
          initialized: true,
          state,
          token: null,
        };
      }

      return {
        ...prev,
        state,
      };
    });

    window.location.href = constructAuthorizeUrl({ state, userType });
  }, [setState, userType]);
};
