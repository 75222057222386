interface LikeIconProps {
  color?: string;
}
const LikeIcon = ({color}:LikeIconProps) => (
<svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M0 5.91279V5.31093C0.044832 5.03417 0.0806977 4.7574 0.134496 4.48063C0.578333 2.31042 2.35368 0.228067 5.19155 0.0171965C6.62617 -0.088239 7.93079 0.289571 9.0471 1.19456C9.50887 1.56798 9.92133 2.0029 10.3472 2.42025C10.4683 2.53886 10.549 2.55643 10.6745 2.42464C11.0107 2.07758 11.3425 1.73052 11.7012 1.41422C13.0775 0.214888 14.6915 -0.224426 16.4937 0.109452C19.2778 0.62345 21.0397 3.0924 20.9993 5.7151C20.9769 7.16045 20.421 8.41689 19.3809 9.44927C16.6013 12.217 13.8172 14.9758 11.0332 17.7391C10.679 18.0906 10.3383 18.0862 9.98409 17.7303C7.21347 14.9802 4.44734 12.2345 1.67672 9.48442C0.914573 8.7288 0.390039 7.84138 0.156912 6.80021C0.089664 6.50587 0.0537984 6.21153 0 5.91279Z"
    fill={color?color:"#575756"} />
</svg>
)

export default LikeIcon