import React from 'react';
import { TouchableOpacity, Animated } from 'react-native';
import LikeIcon from '../../../assets/social-icons/like-inactive';

interface LikeButtonProps {
  isLiked: boolean;
  handleLike: () => void;
  scale: Animated.Value;
}

const LikeButton: React.FC<LikeButtonProps> = ({
  isLiked,
  handleLike,
  scale,
}) => {
  const likeIconStyle = {
    transform: [{ scale }],
  };

  return (
    <TouchableOpacity onPress={handleLike}>
      <Animated.View style={likeIconStyle}>
        <LikeIcon color={isLiked ? '#D2000C' : '#575756'} />
      </Animated.View>
    </TouchableOpacity>
  );
};

export default LikeButton;
