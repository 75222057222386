import React, { useState, useRef, useEffect } from 'react';
import { Video, ResizeMode } from 'expo-av';
import {
  View,
  Text,
  Image,
  StyleSheet,
  Pressable,
  Animated,
  TouchableOpacity,
  Platform,
} from 'react-native';
import { Comment, Like } from '../../types';
import Svg from 'react-native-svg';
import { colourConst, fontFamilyConst, fontSize } from '../../globalStyles';
import CommentIcon from '../../../assets/social-icons/comment';
import Constants from 'expo-constants';
import { useSecureContext } from '../../context';
import {
  useNavigation,
  NavigationProp,
  ParamListBase,
} from '@react-navigation/native';
import TruncatedText from '../TextComponents/TruncatedText';
import DeleteModal from './DeleteModal';
import { timeSince } from '../../helpers/date-time';
import LikeButton from './LikeButton';
const { STRAPI_BASE_URL, NEXT_PUBLIC_ASSETS_BASE_URL } =
  Constants.expoConfig!.extra!;
export interface SocialPost {
  imageUrl: string;
  mediaType: string;
  text: string;
  likes: Like[];
  comments: Comment[];
  date: string;
  id: number;
  firstName: string;
  lastName: string;
  participantId: string;
  refetch: () => void;
}

const SocialPost = ({
  imageUrl,
  mediaType,
  text,
  likes,
  comments,
  date,
  id,
  firstName,
  lastName,
  participantId,
  refetch,
}: SocialPost) => {
  const navigation = useNavigation<NavigationProp<ParamListBase>>();
  const { userData, token, setState, contextTokens } = useSecureContext();
  const cmsApiAccessKey = contextTokens?.CmsApiAccessKey;
  const [tempImageUrl, setTempImageUrl] = useState('');
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const numberOfComments = comments?.length;
  const [scale, setScale] = useState(new Animated.Value(1));
  const isWeb = Platform.OS === 'web';
  console.log('isWeb', isWeb);
  const scaleValue = useRef(scale).current;
  const isLiked = likes.find(
    (like) => like.attributes.Participant_Id === userData?.ParticipantId
  );
  const isOwnPost = participantId === userData?.ParticipantId;
  useEffect(() => {
    fetch(
      `${NEXT_PUBLIC_ASSETS_BASE_URL}/digital-assets/urls/view/${
        mediaType === 'video' ? '2' : '1'
      }/${imageUrl}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        if (res.status === 401) {
          setState((prev) => ({
            ...prev,
            token: null,
            state: null,
          }));
          throw new Error('Unauthorized');
        }
        return res.json();
      })
      .then((data) => {
        setTempImageUrl(data.Url);
      })
      .catch((error) => {
        if (
          !!error &&
          typeof error === 'object' &&
          'status' in error &&
          error.status === 401
        ) {
          setState((prev) => ({
            ...prev,
            token: null,
            state: null,
          }));
        }

        throw error;
      });
  }, [imageUrl, token, setState]);
  const handleLike = () => {
    if (!isLiked) {
      Animated.sequence([
        Animated.timing(scaleValue, {
          toValue: 1.3,
          duration: 100,
          useNativeDriver: true,
        }),
        Animated.timing(scaleValue, {
          toValue: 1,
          duration: 100,
          useNativeDriver: true,
        }),
      ]).start();

      fetch(`${STRAPI_BASE_URL}/api/likes`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${cmsApiAccessKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: {
            Participant_Id: userData?.ParticipantId,
            social_post: id,
          },
        }),
      })
        .then((res) => res.json())
        .then((data) => refetch())
        .catch((err) => console.log('err', err));
    } else {
      fetch(`${STRAPI_BASE_URL}/api/likes/${isLiked.id}`, {
        headers: {
          Authorization: `Bearer ${cmsApiAccessKey}`,
        },
        method: 'DELETE',
      })
        .then((res) => res.json())
        .then((data) => refetch())
        .catch((err) => console.log('err', err));
    }
  };
  const handleDelete = () => {
    setIsDeleteModalVisible(true);
  };

  const handleConfirmDelete = () => {
    fetch(`${STRAPI_BASE_URL}/api/social-posts/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${cmsApiAccessKey}`,
      },
    })
      .then((res) => res.json())
      .then((data) => refetch())
      .catch((err) => console.log('err', err));
    setIsDeleteModalVisible(false);
  };

  const handleCancelDelete = () => {
    setIsDeleteModalVisible(false);
  };
  const WebVideo = ({ uri }) => (
    <video
      style={{ width: '93%', height: 'auto', borderRadius: 10 }}
      controls
      preload="metadata"
      playsInline
    >
      <source src={`${uri}#t=0.001`} type="video/mp4" />
    </video>
  );
  const MediaContent = () => {
    if (mediaType === 'video') {
      return isWeb ? (
        <WebVideo uri={tempImageUrl} />
      ) : (
        <Video
          source={{ uri: tempImageUrl }}
          style={{ width: '93%', height: '100%', borderRadius: 10 }}
        />
      );
    } else if (tempImageUrl) {
      return <Image style={styles.media} source={{ uri: tempImageUrl }} />;
    } else {
      return (
        <Image
          style={styles.loader}
          source={require('../../../assets/loader.gif')}
        />
      );
    }
  };

  if (!setTempImageUrl) {
    return (
      <Image
        style={styles.loader}
        source={require('../../../assets/loader.gif')}
      />
    );
  }

  return (
    <View style={styles.post}>
      <View style={styles.userInfoContainer}>
        <View>
          <Text style={styles.userName}>
            {firstName} {lastName}
          </Text>
          <Text style={styles.date}>{timeSince(date)}</Text>
        </View>
        {isOwnPost ? (
          <TouchableOpacity onPress={handleDelete} style={styles.deleteButton}>
            <Text style={styles.deleteText}>...</Text>
          </TouchableOpacity>
        ) : null}
        <DeleteModal
          isVisible={isDeleteModalVisible}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
        />
      </View>
      <TruncatedText
        text={text}
        maxLength={125}
        style={styles.imageDescription}
      />
      <MediaContent />
      <View style={styles.footer}>
        <View style={styles.likeContainer}>
          <TouchableOpacity style={styles.iconWrapper} onPress={handleLike}>
            <LikeButton
              isLiked={!!isLiked}
              handleLike={handleLike}
              scale={scale}
            />
          </TouchableOpacity>
          <Text style={styles.commentLikesText}>{likes?.length}</Text>
        </View>

        <View style={styles.commentContainer}>
          <Pressable
            style={styles.iconWrapper}
            onPress={() => navigation.navigate('Comments', { id })}
          >
            <Svg width={20} height={20} viewBox="0 0 20 20">
              <CommentIcon />
            </Svg>
          </Pressable>
          <Text style={styles.commentLikesText}>{numberOfComments}</Text>
        </View>
        <Text style={styles.id}>id: {id}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  media: {
    width: 320,
    height: 280,
    borderRadius: 10,
    alignSelf: 'center',
  },
  post: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 350,
    marginVertical: 10,
    padding: 0,
    backgroundColor: 'white',
    borderRadius: 10,
    filter: 'drop-shadow(0 5 5 rgba(0, 0, 0, 0.25))',
  },

  footer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    height: 50,
    padding: 10,
  },

  likeContainer: {
    flexDirection: 'row',
    position: 'relative',
    left: 4,
    top: 6,
  },
  commentContainer: {
    flexDirection: 'row',
    position: 'relative',
    left: 25,
    top: 6,
  },

  iconWrapper: {
    width: 22,
    height: 22,
    marginHorizontal: 3,
  },
  commentLikesText: {
    fontFamily: fontFamilyConst.regular,
    color: colourConst.font,
    fontSize: fontSize.small,
    lineHeight: 18,
  },
  userInfoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: 50,
    padding: 10,
    paddingLeft: 15,
    paddingRight: 5,
  },
  userName: {
    fontFamily: fontFamilyConst.bold,
    fontSize: fontSize.regular,
    color: colourConst.font,
    lineHeight: 24,
  },
  date: {
    fontFamily: fontFamilyConst.bold,
    fontSize: fontSize.small,
    color: 'rgba(87, 87, 86, 0.5)',
    lineHeight: 18,
  },

  deleteButton: {
    paddingBottom: 10,
  },
  deleteText: {
    color: colourConst.font,
    fontWeight: 'bold',
    fontSize: 20,
  },
  imageDescription: {
    width: '100%',
    fontFamily: fontFamilyConst.regular,
    fontSize: fontSize.smallRegular,
    color: colourConst.font,
    lineHeight: 14,
    paddingHorizontal: 10,
    paddingTop: 8,
    paddingBottom: 18,
    textAlign: 'left',
  },
  loader: {
    width: 100,
    height: 100,
    alignSelf: 'center',
    resizeMode: 'contain',
  },
  id: {
    alignSelf: 'flex-end',
    position: 'relative',
    left: 234,
    top: 0,
    fontFamily: fontFamilyConst.regular,
    fontSize: 6,
    color: colourConst.font,
  },
});

export default SocialPost;
