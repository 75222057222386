import {
  useSharedValue,
  useAnimatedStyle,
  withSpring,
  withTiming,
} from 'react-native-reanimated';
import { Gesture } from 'react-native-gesture-handler';
import { useEffect } from 'react';
import { getPerson } from '../../helpers/get-agenda';
import { timeslots } from '../../globalStyles';
import useGetAssets from '../../hooks/useGetAssets';
import { useQueryWithToken } from '../../query';

export const useAgendaEvent = (
  activeTab: number | undefined,
  imageUrl: string,
  isHomepage = true
) => {
  const { data } = useQueryWithToken('person', getPerson);
  const firstName = data?.FirstName;
  const isExpanded = useSharedValue(false);
  const opacityAnimated = useSharedValue(0);
  const animatedStyles = useAnimatedStyle(() => {
    return {
      boxShadow: '0px 8px 10px rgba(3, 92, 167, 0.3)',
      height: withTiming(isExpanded.value ? 200 : 0, {
        duration: 300,
      }),
    };
  });
  const containerStyle = useAnimatedStyle(() => {
    return {
      borderBottomLeftRadius: isExpanded.value ? 0 : timeslots.radius,
      borderBottomRightRadius: isExpanded.value ? 0 : timeslots.radius,
      boxShadow: '0px 3px 10px rgba(3, 92, 167, 0.3)',
      opacity: opacityAnimated.value,
    };
  });
  const onLayoutEffect = () => {
    opacityAnimated.value = withTiming(1, {
      duration: 50,
    });
  };
  const animatedBorder = useAnimatedStyle(() => {
    return {
      borderWidth: isExpanded.value ? 2 : 0,
      height: withTiming(isExpanded.value ? 200 : 0, {
        duration: 300,
      }),
    };
  });
  const singleTap = Gesture.Tap().onEnd(() => {
    isExpanded.value = !isExpanded.value;
  });
  useEffect(() => {
    isExpanded.value = false;
  }, [activeTab, isExpanded]);
  /////////////
  const { imageToDisplay } = useGetAssets(imageUrl, isHomepage);

  return {
    animatedStyles,
    singleTap,
    containerStyle,
    animatedBorder,
    firstName,
    imageToDisplay,
    onLayoutEffect,
  };
};
