import { TabIconProp } from '../../utils/types';
import { View, StyleSheet } from 'react-native';
import React from 'react';

const MoreIcon = ({ focused }: TabIconProp) => {
  return (
    <View style={styles.img}>
      <svg
        width="28"
        height="29"
        viewBox="0 0 28 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_1221_2296"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="28"
          height="29"
        >
          <path
            d="M13.1752 0C13.7242 0 14.2731 0 14.8109 0C14.9005 0.0112034 14.9902 0.0336104 15.0798 0.0448138C15.696 0.134441 16.3122 0.179254 16.9171 0.313695C20.3006 1.05312 23.0678 2.78965 25.1405 5.55689C27.6948 8.95152 28.5351 12.7831 27.6724 16.9508C26.9778 20.3006 25.2189 23.023 22.5077 25.1068C19.5836 27.3587 16.2562 28.2886 12.5926 27.9413C9.57893 27.65 6.91252 26.4625 4.66063 24.4346C2.2631 22.2724 0.773052 19.606 0.212881 16.4242C0.123254 15.8976 0.0672205 15.3599 0 14.8333C0 14.2843 0 13.7354 0 13.1864C0.0224068 12.9848 0.0448246 12.7943 0.0672314 12.5926C0.403334 9.57892 1.55727 6.92371 3.58509 4.67182C5.73615 2.27429 8.41376 0.784239 11.5955 0.224068C12.1221 0.134441 12.6598 0.0784237 13.1864 0.0112032L13.1752 0ZM7.2038 16.1329C8.35775 16.1329 9.33244 15.147 9.32123 14.0043C9.32123 12.8391 8.33533 11.8756 7.19258 11.8868C6.03863 11.9092 5.09757 12.8503 5.08636 14.0043C5.08636 15.1582 6.06105 16.1329 7.2038 16.1329ZM22.8998 14.0043C22.8886 12.8391 21.9027 11.8644 20.7599 11.898C19.5948 11.9204 18.6649 12.8727 18.6537 14.0155C18.6537 15.1694 19.6284 16.1441 20.7823 16.1441C21.9363 16.1441 22.911 15.1582 22.8998 14.0155V14.0043ZM11.8644 14.0155C11.8644 15.1694 12.8503 16.1441 13.9931 16.1329C15.147 16.1329 16.0881 15.1806 16.1105 14.0267C16.1329 12.8839 15.1582 11.898 13.9931 11.8868C12.8391 11.8868 11.8644 12.8615 11.8644 14.0155Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_1221_2296)">
          <path
            d="M51.1436 -6.04984H-349.849V34.8426H51.1436V-6.04984Z"
            fill={focused ? 'url(#paint0_linear_1221_2296)' : '#AFAFAF'}
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_1221_2296"
            x1="-3.41704"
            y1="14.3964"
            x2="138.284"
            y2="14.3964"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#2A80BC" />
            <stop offset="0.2" stopColor="#5D1276" />
          </linearGradient>
        </defs>
      </svg>
    </View>
  );
};
const styles = StyleSheet.create({
  img: {
    width: 30,
    height: 80,
    alignItems: 'center',
  },
});

export default MoreIcon;
