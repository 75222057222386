import React from 'react';
import { Modal, View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { fontFamilyConst, fontSize, colourConst } from '../../globalStyles';

interface DeleteModalProps {
  isVisible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  isVisible,
  onConfirm,
  onCancel,
}) => {
  return (
    <Modal visible={isVisible} animationType="slide" transparent={true}>
      <View style={styles.deleteModal}>
        <Text style={styles.deleteModalText}>
          Are you sure you want to delete this post?
        </Text>
        <View style={styles.deleteModalButtonsContainer}>
          <TouchableOpacity style={styles.deleteModalButton} onPress={onCancel}>
            <Text style={styles.deleteModalButtonText}>Cancel</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.deleteModalButton}
            onPress={onConfirm}
          >
            <Text style={styles.deleteModalButtonText}>Delete</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  deleteModal: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  deleteModalText: {
    fontFamily: fontFamilyConst.regular,
    fontSize: fontSize.medium,
    color: 'white',
    lineHeight: 24,
    marginBottom: 20,
    textAlign: 'center',
  },
  deleteModalButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  deleteModalButton: {
    backgroundColor: 'red',
    width: 100,
    height: 40,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 10,
  },
  deleteModalButtonText: {
    fontFamily: fontFamilyConst.regular,
    fontSize: fontSize.medium,
    color: 'white',
    lineHeight: 24,
  },
});

export default DeleteModal;
