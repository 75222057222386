export const useInfoActivity = (
  activityViewDetail: { Name: string; Content: string }[]
) => {
  const time = activityViewDetail.find(
    (item) => item.Name === 'Transfer Time'
  )?.Content;
  const rating = Number(
    activityViewDetail.find((item) => item.Name === 'Difficulty Rating')
      ?.Content
  );
  let difficulty;
  switch (rating) {
    case 1:
      difficulty = 'Easy';
      break;
    case 2:
      difficulty = 'Easy/Moderate';
      break;
    case 3:
      difficulty = 'Moderate';
      break;
    case 4:
      difficulty = 'Moderate/Hard';
      break;
    case 5:
      difficulty = 'Hard';
      break;
    default:
      difficulty = 'Moderate';
      break;
  }
  const lunch = activityViewDetail.find(
    (item) => item.Name === 'Lunch Provided'
  )?.Content;
  const transferTime = {
    title: 'Transfer Time',
    img: require('../../../assets/InfoIcons/box-transfer.png'),
    info: `${time} min`,
  };
  const difficultyRating = {
    title: 'Difficulty Rating ',
    img: require('../../../assets/InfoIcons/box-rating.png'),
    info: difficulty,
  };
  const lunchIncluded = {
    title: 'Lunch Included',
    img: require('../../../assets/InfoIcons/box-lunch.png'),
    info: `${lunch}`,
  };

  return { transferTime, difficultyRating, lunchIncluded };
};
