export function convertDateFormat(dateString: string | number | Date) {
  const date = new Date(dateString);

  return {
    time: date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: 'UTC',
    }),
    day: date.getUTCDate(),
    dayOfWeek: date.toLocaleString('default', {
      weekday: 'long',
      timeZone: 'UTC',
    }),
    dayOfWeekShort: date.toLocaleString('default', {
      weekday: 'short',
      timeZone: 'UTC',
    }),
    month: date.toLocaleString('default', {
      month: 'long',
      timeZone: 'UTC',
    }),
    monthShort: date.toLocaleString('default', {
      month: 'short',
      timeZone: 'UTC',
    }),
    year: date.getUTCFullYear(),
  };
}

// export return function convertNotificationTime(dateString) {
//   const date = new Date(dateString);
//   time: date.toLocaleString('en-US', {
//     hour: 'numeric',
//     minute: 'numeric',
//     hour12: true,
//     timeZone: 'UTC',
//   })

// }

export function getDayMonth(dateString: string) {
  return `${convertDateFormat(dateString).dayOfWeekShort} ${
    convertDateFormat(dateString).day
  } ${convertDateFormat(dateString).month} ${
    convertDateFormat(dateString).year
  }`;
}

export function timeSince(date: string): string {
  // const dateWithoutTimezone = date.replace('Z', '');
  const seconds = Math.floor(
    (new Date().getTime() - new Date(date).getTime()) / 1000
  );

  let interval = seconds / 31536000;
  if (interval > 1) {
    return Math.floor(interval) + ' years';
  }

  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + ' months';
  }

  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + ' d';
  }

  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + ' h';
  }

  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + ' m';
  }

  return Math.floor(seconds) + ' s';
}

export function timeSinceNotifications(date: string): string {
  const seconds = Math.floor(
    (new Date().getTime() - new Date(date).getTime()) / 1000
  );

  let interval = seconds / 31536000;
  if (interval > 1) {
    return Math.floor(interval) + ' years';
  }

  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + ' months';
  }

  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + ' d';
  }

  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + ' h';
  }

  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + ' m';
  }

  return Math.floor(seconds) + ' s';
}
