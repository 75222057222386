import { useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getPublicVapidKey } from '../helpers/notifications';
import { urlBase64ToUint8Array } from '../helpers/helpers';
import Constants from 'expo-constants';

const SUBSCRIPTION_STORAGE_KEY = 'pushNotificationSubscription';
const PERMISSION_STORAGE_KEY = 'notificationPermission';
const { NEXT_PUBLIC_PEOPLE_BASE_URL } = Constants.expoConfig!.extra!;

const usePushNotifications = (token) => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [subscriptionEndpoint, setSubscriptionEndpoint] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [notificationPermission, setNotificationPermission] =
    useState('default');
  //TODO: Explore the situation where we save permission "granted" into local storage, but user disables notifications from settings
  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      try {
        const storedPermission = await AsyncStorage.getItem(
          PERMISSION_STORAGE_KEY
        );
        if (storedPermission) {
          setNotificationPermission(storedPermission);
        }

        const subscription = await retrieveSubscriptionFromStorage();
        if (subscription) {
          setSubscriptionEndpoint(subscription.endpoint);
          const response = await fetch(
            `${NEXT_PUBLIC_PEOPLE_BASE_URL}/notification-subscriptions?endpoint=${subscription.endpoint}`,
            {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await response.json();
          console.log('Subscription data in useEffect:', data);
          setIsSubscribed(
            data.length > 0 && data[0].Endpoint === subscription.endpoint
          );
        }
      } catch (error) {
        console.error('Failed to check subscription status:', error);
      }
      setIsLoading(false);
    };

    checkSubscriptionStatus();
  }, [token]);
  const requestNotificationPermissionAndSubscribe = async () => {
    if ('Notification' in window) {
      const permission = await Notification.requestPermission();
      setNotificationPermission(permission);
      await AsyncStorage.setItem(PERMISSION_STORAGE_KEY, permission);

      if (permission === 'granted') {
        if ('serviceWorker' in navigator && 'PushManager' in window) {
          try {
            const registration = await navigator.serviceWorker.register(
              '/sw.js'
            );
            console.log('Service Worker registered:', registration);

            if (token !== null) {
              const publicKeyData = await getPublicVapidKey(token);
              const publicKey = publicKeyData.Key;
              const applicationServerKey = urlBase64ToUint8Array(publicKey);

              const existingSubscription =
                await registration.pushManager.getSubscription();

              if (!existingSubscription) {
                const subscription = await registration.pushManager.subscribe({
                  userVisibleOnly: true,
                  applicationServerKey: applicationServerKey,
                });
                console.log('Subscribed to push notifications:', subscription);
                await storePushSubscription(subscription);
                setIsSubscribed(true);
                setSubscriptionEndpoint(subscription.endpoint);
              } else {
                setIsSubscribed(true);
                setSubscriptionEndpoint(existingSubscription.endpoint);
              }
            } else {
              console.error('Token is not available.');
            }
          } catch (error) {
            console.error(
              'Failed to register Service Worker or subscribe:',
              error
            );
          }
        }
      }
    }
  };
  const subscribe = async () => {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      try {
        const registration = await navigator.serviceWorker.ready;
        const publicKeyData = await getPublicVapidKey(token);
        const publicKey = publicKeyData.Key;
        const applicationServerKey = urlBase64ToUint8Array(publicKey);
        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: applicationServerKey,
        });
        console.log('Subscribed ', subscription);
        await AsyncStorage.setItem(
          SUBSCRIPTION_STORAGE_KEY,
          JSON.stringify(subscription)
        );
        return subscription;
      } catch (error) {
        console.error('Failed to subscribe:', error);
        throw error;
      }
    }
  };

  const unsubscribe = async () => {
    try {
      const subscription = await retrieveSubscriptionFromStorage();
      if (subscription) {
        await deleteSubscription(subscription.endpoint);
        await AsyncStorage.removeItem(SUBSCRIPTION_STORAGE_KEY);
      }
    } catch (error) {
      console.error('Failed to unsubscribe:', error);
      throw error;
    }
  };

  const retrieveSubscriptionFromStorage = async () => {
    try {
      const subscriptionJson = await AsyncStorage.getItem(
        SUBSCRIPTION_STORAGE_KEY
      );
      if (subscriptionJson) {
        return JSON.parse(subscriptionJson);
      }
      return null;
    } catch (error) {
      console.error('Failed to retrieve subscription from storage:', error);
      throw error;
    }
  };

  const deleteSubscription = async (endpoint) => {
    try {
      const subscriptionUrl = `${NEXT_PUBLIC_PEOPLE_BASE_URL}/notification-subscriptions?endpoint=${encodeURIComponent(
        endpoint
      )}`;
      await fetch(subscriptionUrl, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
          ETag: '0',
        },
      });
      await AsyncStorage.removeItem(SUBSCRIPTION_STORAGE_KEY);
    } catch (error) {
      console.error('Failed to delete subscription:', error);
      throw error;
    }
  };

  const subscribeAndSendSubscription = async () => {
    try {
      const subscription = await subscribe();
      await storePushSubscription(subscription);
      setSubscriptionEndpoint(subscription.endpoint);
      setIsSubscribed(true);
    } catch (error) {
      console.error('Failed to subscribe:', error);
    }
  };

  const unsubscribeAndDeleteSubscription = async () => {
    try {
      await unsubscribe();
      await deleteSubscription(subscriptionEndpoint);
      setIsSubscribed(false);
    } catch (error) {
      console.error('Failed to unsubscribe:', error);
    }
  };

  const storePushSubscription = async (subscription) => {
    const subscriptionUrl =
      NEXT_PUBLIC_PEOPLE_BASE_URL + '/notification-subscriptions';
    const response = await fetch(subscriptionUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ETag: '0',
      },
      body: JSON.stringify(subscription),
    });
    console.log('Subscription stored:', response);
  };

  return {
    isSubscribed,
    isLoadingSubscriptionStatus: isLoading,
    notificationPermission,
    requestNotificationPermissionAndSubscribe,
    unsubscribeAndDeleteSubscription,
    subscribeAndSendSubscription,
  };
};

export default usePushNotifications;
