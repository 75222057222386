import { SocialPost } from '../types';
import Constants from 'expo-constants';

const { STRAPI_BASE_URL } = Constants.expoConfig!.extra!;

export const getComments = (token: string, socialPostId: string) => {
  const pagesUrl = `${STRAPI_BASE_URL}/api/comments?filters[social_post][id][$eq]=${socialPostId}&populate=*}`;
  return fetch(pagesUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json() as Promise<Comment>;
    }

    throw response;
  });
};

export const createComment = (
  token: string | undefined,
  text: string,
  socialPostId: string,
  familyName: string,
  givenName: string
) => {
  const pagesUrl = `${STRAPI_BASE_URL}/api/comments`;

  return fetch(pagesUrl, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      data: {
        Text: text,
        social_post: socialPostId,
        Family_Name: familyName,
        Given_Name: givenName,
      },
    }),
  }).then((response) => {
    if (response.ok) {
      return response.json() as Promise<SocialPost>;
    }
    console.log(response);

    throw response;
  });
};
