import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { colourConst, fontSize, fontFamilyConst } from '../globalStyles';
import RainbowLine from '../components/RainbowLine';
import TabAgendaLoader from '../components/TabAgendaLoader';
import { ImageBackground, StyleSheet, View } from 'react-native';

const Stack = createStackNavigator();

export const AgendaScreen = () => {
  return (
    <View style={styles.pageContainer}>
      <RainbowLine>
        <ImageBackground
          source={require('../../assets/watermark.png')}
          style={styles.background}
        >
          <TabAgendaLoader />
        </ImageBackground>
      </RainbowLine>
    </View>
  );
};
const styles = StyleSheet.create({
  pageContainer: {
    flex: 1,
    width: '100%',
    maxWidth: 600,
    justifyContent: 'center',

    alignSelf: 'center',
  },
  background: {
    flex: 1,
  },
});

const AgendaNavigation = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        cardStyle: {
          backgroundColor: colourConst.background,
          marginBottom: 0,
          flex: 1,
        },
      }}
    >
      <Stack.Screen
        name="Agenda"
        component={AgendaScreen}
        options={{
          headerTitle: 'Your Agenda',
          headerTitleAlign: 'center',
          headerTintColor: colourConst.blue,
          headerTitleStyle: {
            fontFamily: fontFamilyConst.bold,
            fontSize: fontSize.large,
          },
        }}
      />
    </Stack.Navigator>
  );
};

export default React.memo(AgendaNavigation);
