import React from 'react';
import { StyleSheet, Dimensions } from 'react-native';
import { TextProps } from '../../../../utils/types';
import {
  fontFamilyConst,
  colourConst,
  fontSize,
} from '../../../../globalStyles';
import Animated, {
  withTiming,
  withDelay,
  Easing,
} from 'react-native-reanimated';
import useTextAnimation from '../../../../hooks/useTextAnimation';

const TextRequestPassword = ({ children }: TextProps) => {
  const { textOpacity } = useTextAnimation();
  return (
    <Animated.Text
      style={style.text}
      numberOfLines={3}
      onLayout={() => {
        textOpacity.value = withDelay(
          500,
          withTiming(1, {
            duration: 500,
            easing: Easing.linear,
          })
        );
      }}
    >
      {children}
    </Animated.Text>
  );
};
const style = StyleSheet.create({
  text: {
    fontFamily: fontFamilyConst.bold,
    fontSize: fontSize.small,
    color: colourConst.unselected,
    textAlign: 'center',
    textAlignVertical: 'center',
    flexShrink: 1,
    textTransform: 'uppercase',
    textDecorationLine: 'underline',
  },
});
export default TextRequestPassword;
