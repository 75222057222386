import React from 'react';
import { Text, StyleSheet } from 'react-native';
import {
  colourConst,
  fontSize,
  fontFamilyConst,
} from '../../../../globalStyles';

interface TextUserPointsProps {
  content: string;
}
const TextUserPoints = (props: TextUserPointsProps) => {
  return <Text style={styles.text}>{props.content}</Text>;
};

const styles = StyleSheet.create({
  text: {
    fontFamily: fontFamilyConst.bold,
    fontSize: fontSize.smallMedium,
    color: colourConst.blue,
    textAlign: 'center',
  },
});
export default TextUserPoints;
