import { useState } from 'react';

const useLogin = () => {
  const [isRequested, setIsRequested] = useState(false);
  const handleIsRequested = () => setIsRequested(!isRequested);

  return { isRequested, handleIsRequested, setIsRequested };
};

export default useLogin;
