import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSecureContext } from '../context';
import { getStrapiToken } from '../helpers/get-strapi-token';
import { getSocialPosts } from '../helpers/get-social-posts';
export const useGetPostsWithToken = () => {
  const { locationAndWave, token, setState, initialized } = useSecureContext();
  const [fallbackCmsToken, setFallbackCmsToken] = useState<string | null>(null);
  const wave = locationAndWave?.wave?.ProjectId;
  useEffect(() => {
    getStrapiToken(token).then((strapiTokenResponse) => {
      setFallbackCmsToken(strapiTokenResponse.jwt);
      setState((prev) => {
        if (!prev || !initialized) {
          return prev;
        }

        if (!prev.strapiToken) {
          return {
            ...prev,
            strapiToken: strapiTokenResponse.jwt,
          };
        }

        return prev;
      });
    });
  }, [token]);
  const { data: strapiApiResponse } = useQuery(['strapiToken'], () =>
  getStrapiToken(token)
  );
  const cmsToken = strapiApiResponse?.jwt;

  const validToken = cmsToken || fallbackCmsToken;
  // Then get the user's projects
  const {
    isIdle,
    isLoading,
    isError,
    error,
    refetch,
    data: posts,
  } = useQuery(
    ['socialPosts', cmsToken, wave, fallbackCmsToken, validToken],
    () => getSocialPosts(validToken, wave),
    {
      // The query will not execute until the userId exists
      enabled: !!cmsToken,
    }
  );

  return { posts, isError, isLoading, isIdle, error, refetch };
};
