import { LinearGradient } from 'expo-linear-gradient';
import React, { useEffect } from 'react';
import { View, StyleSheet, Button, Pressable } from 'react-native';
import { myTheme } from '../../theme';
import {
  Avatar,
  Card,
  Text,
  Provider as PaperProvider,
} from 'react-native-paper';
import { colourConst, fontFamilyConst, fontSize } from '../../globalStyles';
import { updateNotifications } from '../../helpers/notifications';
import { useSecureContext } from '../../context';
import { timeSinceNotifications } from '../../helpers/date-time';
const priorityTheme = {
  ...myTheme, // or MD3DarkTheme
  roundness: 2,
  colors: {
    ...myTheme.colors,
    primary: '#D2000C',
    secondary: '#f1c40f',
    tertiary: '#a1b2c3',
    FontFace: 'coreSansExtraBold',
  },
};
const standardTheme = {
  ...myTheme, // or MD3DarkTheme
  roundness: 2,
  colors: {
    ...myTheme.colors,
    primary: '#118C25',
    secondary: '#f1c40f',
    tertiary: '#a1b2c3',
    FontFace: 'coreSansExtraBold',
  },
};

const disabled_theme = {
  ...myTheme, // or MD3DarkTheme
  roundness: 2,
  colors: {
    ...myTheme.colors,
    primary: '#555',
    secondary: '#f1c40f',
    tertiary: '#a1b2c3',
  },
};
const high_priority_theme = {
  ...myTheme,
  roundness: 2,
  colors: {
    ...myTheme.colors,
    primary: '#D2000C',
    secondary: '#D2000C',
    tertiary: '#D2000C',
  },
};
const readTheme = {
  colors: {
    primary: colourConst.inactiveGray,
  },
};

const unreadTheme = {
  colors: {
    primary: colourConst.green,
  },
};
const importantTheme = {
  colors: {
    primary: colourConst.red,
  },
};

type NotificationProps = {
  NotificationTitle: string;
  NotificationBody: string;
  canMarkAsRead: boolean;
  NotificationTime: string;
  NotificationId: string;
  NotificationStatusTypeId: number;
  NotificationPriorityTypeId: number;
  isUnread: boolean;
  isImportant: boolean;
  refetchNotifications: () => void;
};

const NotificationItem = (props: NotificationProps) => {
  const { token, userData } = useSecureContext();
  const participantId = userData?.ParticipantId;
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [isRead, setIsRead] = React.useState(!props.isUnread);

  useEffect(() => {
    setIsRead(!props.isUnread);
  }, [props.isUnread]);
  const handlePress = () => {
    setIsRead(true);
    updateNotifications(token, participantId, props.NotificationId).then(() => {
      props.refetchNotifications();
    });
  };
  const LeftContent = () => (
    <Avatar.Icon
      {...props}
      size={25}
      color="white"
      icon="bell"
      // theme={props.isImportant ? priorityTheme : standardTheme}
      theme={
        props.isImportant ? importantTheme : isRead ? readTheme : unreadTheme
      }
    />
  );
  const RightContent = () => (
    <Text {...props} style={styles.rightContent} variant="titleSmall">
      {timeSinceNotifications(props.NotificationTime)}
    </Text>
  );

  return (
    <PaperProvider theme={isDisabled ? disabled_theme : standardTheme}>
      <Card
        onPress={handlePress}
        mode={isDisabled ? 'contained' : 'elevated'}
        style={[
          styles.card,
          !props.isImportant
            ? styles.ElevatedNotification
            : styles.standardNotification,
        ]}
      >
        <View
          style={{
            ...styles.linearGradient,
            backgroundColor: isRead ? '#FFFFFF' : 'transparent',
            borderRadius: 10,
            // padding: 2
          }}
        >
          <LinearGradient
            style={styles.linearGradient}
            colors={isRead ? ['#FFFFFF', '#FFFFFF'] : gradientColours}
          >
            <Card.Content style={styles.content}>
              <Card.Title
                title={props.NotificationTitle}
                // subtitle={props.NotificationTitle}
                style={styles.title}
                titleVariant="titleSmall"
                left={LeftContent}
                right={RightContent}
                leftStyle={styles.leftStyle}
                rightStyle={styles.rightStyle}
                titleStyle={styles.titleText}
                titleNumberOfLines={2}
              />

              {/* <Text variant="titleLarge">Card title</Text> */}
              <Text style={styles.notificationbody} variant="headlineSmall">
                {props.NotificationBody}
              </Text>
            </Card.Content>

            {/* <Card.Cover source={{ uri: 'https://picsum.photos/700' }} /> */}
            {/* <Card.Actions> */}
            {/* <Button>Cancel</Button>
          <Button>Ok</Button> */}
            {/* </Card.Actions> */}
          </LinearGradient>
        </View>
      </Card>
    </PaperProvider>
  );
  // return (
  //   <View>
  //     <Pressable onPress={handlePress}>{isRead ? 'Read' : 'Unread'}</Pressable>{' '}
  //   </View>
  // );
};

const styles = StyleSheet.create({
  content: {
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    padding: 2,
    marginTop: 3,
    marginLeft: 3,
    marginRight: 3,
    marginBottom: 3,
    borderRadius: 8,
    paddingBottom: 20,
    fontSize: 4,
    paddingTop: 15,
  },
  rightStyle: {
    marginBottom: 0,
  },
  leftStyle: {
    marginBottom: 0,
    marginRight: 0,
    width: 35,
    height: 30,
    paddingBottom: 10,
  },
  rightContent: {
    minHeight: 30,
    marginBottom: 0,
    color: colourConst.font,
    fontFamily: fontFamilyConst.bold,
  },

  card: {
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    marginTop: 20,
    marginLeft: 20,
    marginRight: 20,
    borderRadius: 10,
  },

  title: {
    paddingLeft: 0,
    minHeight: 10,
    color: colourConst.font,
  },
  titleText: {
    color: colourConst.font,
    fontFamily: fontFamilyConst.bold,
  },
  notificationbody: {
    fontFamily: fontFamilyConst.medium,
    color: colourConst.font,
    fontSize: fontSize.smallRegular,
    lineHeight: fontSize.medium,
  },
  notificationBodyTest: {
    color: 'red',
  },
  standardNotification: {
    backgroundColor: '#fff',
  },
  ElevatedNotification: {
    borderRadius: 10,
  },
  linearGradient: {
    borderRadius: 10,
  },
  highPriority: {
    color: 'D2000C',
  },
});

export default NotificationItem;

export const gradientColours = [
  '#1E3B8D',
  '#0A6226',
  '#118C25',
  '#AC9300',
  '#D4C100',
  '#CE4400',
  '#D2000C',
  '#8B060F',
  '#D30077',
  '#8B3E8D',
  '#2A80BC',
  '#5D1276',
  '#1E3B8D',
];
const highPrioritycolours = ['#D2000C', '#D2000C'];
export const standardColours = ['#FFFFFF'];
export const locations = [
  0, 0.08, 0.17, 0.26, 0.33, 0.41, 0.51, 0.6, 0.7, 0.78, 0.86, 0.93, 1,
];
