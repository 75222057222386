import { TabIconProp } from '../../utils/types';
import { View, StyleSheet } from 'react-native';
import React from 'react';
import Animated, {
  Easing,
  useAnimatedProps,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import Svg from 'react-native-svg';

const MyEventIcon = ({ focused }: TabIconProp) => {
  const AnimatedSvg = Animated.createAnimatedComponent(Svg);
  const scaleAnimated = useSharedValue(1);
  if (focused) {
    scaleAnimated.value = withTiming(1.5, {
      duration: 1000,
      easing: Easing.linear,
    });
  } else {
    scaleAnimated.value = withTiming(1, {
      duration: 1000,
      easing: Easing.linear,
    });
  }
  const animatedProps = useAnimatedStyle(() => {
    return {
      transform: [{ scale: scaleAnimated.value }],
    };
  });
  return (
    <View style={styles.img}>
      <AnimatedSvg
        width={28}
        height={28}
        viewBox="0 0 28 28"
        fill="none"
        // xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1221_2269)">
          <path
            d="M14.8736 28H13.1264C12.8688 27.9664 12.6112 27.9328 12.3424 27.8992C9.6096 27.5744 7.1456 26.5664 5.0736 24.7744C0.851204 21.112 -0.761596 16.4864 0.336004 11.0096C1.5792 4.79361 7.2464 0.145607 13.5744 7.44229e-06C16.296 -0.0559926 18.8384 0.548807 21.1568 1.97121C24.7856 4.18881 27.0032 7.39201 27.7872 11.5808C27.8768 12.0848 27.9328 12.6112 28 13.1152V14.8624C27.9888 14.9296 27.9552 15.008 27.9552 15.0752C27.7536 17.7296 26.8688 20.1376 25.2784 22.2656C23.0496 25.2448 20.104 27.0928 16.4304 27.7648C15.9152 27.8544 15.3888 27.9104 14.8624 27.9776L14.8736 28ZM13.5184 25.032C17.4384 25.032 20.72 23.3968 22.792 20.6304C23.184 20.104 23.296 19.5552 23.0832 18.9392C22.9152 18.4352 22.7696 17.9312 22.6016 17.4272C21.9408 15.3888 20.0368 14.4368 17.9984 15.1312C17.0352 15.456 16.0496 15.6688 15.0416 15.7472C13.2384 15.8816 11.4912 15.6576 9.7664 15.064C7.9744 14.4592 6.0592 15.4336 5.4432 17.2144C5.2416 17.7856 5.0736 18.3568 4.8832 18.928C4.6816 19.5328 4.7712 20.0928 5.1632 20.5968C7.392 23.4752 10.3376 24.9312 13.496 25.032H13.5184ZM13.9776 13.776C16.9568 13.776 19.3984 11.368 19.4096 8.38881C19.4096 5.40961 17.0016 2.96801 14.0224 2.95681C11.0432 2.94561 8.6016 5.37601 8.5904 8.34401C8.5904 11.3232 10.9984 13.7648 13.9776 13.776Z"
            fill={focused ? 'url(#paint0_linear_1221_2269)' : '#AFAFAF'}
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_1221_2269"
            x1="4.04561e-06"
            y1="14"
            x2="28"
            y2="14"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#0A6226" />
            <stop offset="1" stopColor="#118C25" />
          </linearGradient>
          <clipPath id="clip0_1221_2269">
            <rect width="28" height="28" fill="white" />
          </clipPath>
        </defs>
      </AnimatedSvg>
    </View>
  );
};
const styles = StyleSheet.create({
  img: {
    width: 36,
    height: 80,
    alignItems: 'center',
  },
});

export default MyEventIcon;
