import React, {
  createContext,
  ReactNode,
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
  useContext,
} from 'react';
import { SecureStorage } from './storage';
import { LocationWithMaybeWave } from './types';

export interface SecureState {
  initialized: boolean;
  state: string | null;
  token: string | null;
  locationAndWave: null | LocationWithMaybeWave;
  strapiToken: null | string;
  contextTokens: null | {
    CmsApiAccessKey: string;
    OrganizationAuthIdentifier: string;
    OrganizationId: string;
    OrganizationName: string;
    OrganizationReference: string;
    WeatherApiAccessKey: string;
  };
  userData: null | {
    FirstName: string;
    LastName: string;
    ParticipantId: string;
  };
}

export interface FullSecureState extends SecureState {
  setState: (callback: (prev: SecureState) => SecureState) => Promise<void>;
}

const AppContext = createContext<FullSecureState>({
  initialized: false,
  state: null,
  token: null,
  strapiToken: null,
  locationAndWave: null,
  contextTokens: null,
  setState: () => {
    throw new Error('AppContext was accessed outside of an AppContextProvider');
  },
  userData: null,
});

export const SecureContextProvider = ({
  children,
}: {
  children: ReactNode | readonly ReactNode[];
}) => {
  const mountedRef = useRef(true);
  const [state, setState] = useState<Omit<FullSecureState, 'setState'>>({
    initialized: false,
    state: null,
    token: null,
    strapiToken: null,
    locationAndWave: null,
    contextTokens: null,
    userData: null,
  });

  const setStateWrapper = useCallback(
    async (callback: (prev: SecureState) => SecureState) =>
      new Promise<void>((resolve) => {
        setState((prev) => {
          const { initialized, ...next } = callback(prev);

          SecureStorage.setItemAsync('state', JSON.stringify(next)).then(() =>
            resolve()
          );

          return {
            ...next,
            initialized,
          };
        });
      }),
    []
  );

  useEffect(() => {
    const setInitialState = async () => {
      if (mountedRef.current) {
        const stored: SecureState | null = JSON.parse(
          (await SecureStorage.getItemAsync('state')) ?? 'null'
        );

        setState((prev) => {
          if (prev.initialized) {
            return prev;
          }

          return {
            state: stored?.state ?? null,
            token: stored?.token ?? null,
            strapiToken: stored?.strapiToken ?? null,
            initialized: true,
            locationAndWave: stored?.locationAndWave ?? null,
            contextTokens: stored?.contextTokens ?? null,
            userData: stored?.userData ?? null,
          };
        });
      }
    };

    setInitialState();
  }, []);

  useEffect(
    () => () => {
      mountedRef.current = false;
    },
    []
  );

  const fullState = useMemo(
    () => ({
      ...state,
      setState: setStateWrapper,
    }),
    [state, setStateWrapper]
  );

  return (
    <AppContext.Provider value={fullState}>{children}</AppContext.Provider>
  );
};

export const useSecureContext = () => useContext(AppContext);
