import React from 'react';
import { Text, StyleSheet, View } from 'react-native';
import { colourConst, fontSize, fontFamilyConst } from '../../../globalStyles';
import { TextProps } from '../../../utils/types';

const TextMoreCard = (props: TextProps) => {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>{props.children}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  text: {
    fontFamily: fontFamilyConst.bold,
    fontSize: fontSize.regular,
    color: colourConst.font,
    textAlign: 'center',
    textAlignVertical: 'center',
  },
});
export default TextMoreCard;
