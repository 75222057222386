import Constants from 'expo-constants';
import queryString from 'query-string';
import getBaseUrl from '../helpers/getBaseUrl';
const {
  AUTH0_CLIENT_ID,
  AUTH0_ISSUER_BASE_URL,
  AUTH0_ORGANIZATION,
  AUTH0_AUDIENCE,
  AUTH0_GARTNER_OKTA_CONNECTION,
  AUTH0_USERNAME_PASSWORD_CONNECTION,
} = Constants.expoConfig!.extra!;
const urlString = window.location.href;
const baseUrl = getBaseUrl(urlString);

export const constructAuthorizeUrl = ({
  state,
  userType,
}: {
  state: string;
  userType: string;
}) => {
  const connection =
    userType === 'gartner'
      ? AUTH0_GARTNER_OKTA_CONNECTION
      : AUTH0_USERNAME_PASSWORD_CONNECTION;

  const query = queryString.stringify(
    {
      response_type: 'token',
      client_id: AUTH0_CLIENT_ID,
      organization: AUTH0_ORGANIZATION,
      redirect_uri: baseUrl,
      scope: 'openid profile email offline_access',
      audience: AUTH0_AUDIENCE,
      connection,
      state,
    },
    { encode: true }
  );
  return `${AUTH0_ISSUER_BASE_URL}/authorize?${query}`;
};
