import { StyleSheet, View } from 'react-native';
import React from 'react';
import MiniInfo from '../MiniInfo';
import { useInfoActivity } from './utils';
import { InfoActivityProps } from '../../utils/types';

const InfoActivity = ({ activityViewDetail }: InfoActivityProps) => {
  const { transferTime, difficultyRating, lunchIncluded } =
    useInfoActivity(activityViewDetail);
  return (
    <View style={styles.container}>
      <MiniInfo
        title={transferTime.title}
        img={transferTime.img}
        info={transferTime.info}
      />
      <MiniInfo
        title={difficultyRating.title}
        img={difficultyRating.img}
        info={difficultyRating.info}
      />
      <MiniInfo
        title={lunchIncluded.title}
        img={lunchIncluded.img}
        info={lunchIncluded.info}
      />
    </View>
  );
};

export default InfoActivity;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 25,
    marginHorizontal: 15,
  },
});
