import { View, StyleSheet } from 'react-native';
import { EventProps } from '../../utils/types';
import { colourConst, timeslots } from '../../globalStyles';
import { useAgendaEvent } from '../AgendaEvent/utils';
import Animated from 'react-native-reanimated';
import { GestureDetector, Gesture } from 'react-native-gesture-handler';
import TourInfo from '../TourInfo';
import InfoActivity from '../InfoActivity';
import React from 'react';
import { Image } from 'expo-image';
import TextNameTimeslot from '../TextComponents/TextNameTimeslot';
import TextTypeTimeslot from '../TextComponents/TextTypeTimeslot';
import TextActEvent from '../TextComponents/TextActEvent';
import TextTimeEvent from '../TextComponents/TextTimeEvent';

const BookingEvent = ({
  timeBegin,
  timeEnd,
  activityName,
  agendaViewDetail,
  activeTab,
  imageUrl,
}: EventProps) => {
  const {
    singleTap,
    containerStyle,
    animatedBorder,
    firstName,
    imageToDisplay,
    onLayoutEffect,
  } = useAgendaEvent(activeTab, imageUrl, false);
  return (
    <>
      <GestureDetector gesture={Gesture.Exclusive(singleTap)}>
        <Animated.View
          testID="booking container"
          style={[styles.container, containerStyle]}
          onLayout={onLayoutEffect}
        >
          <View style={styles.mainBody}>
            <Image
              source={imageToDisplay}
              style={styles.image}
              contentFit="cover"
              transition={{
                duration: 300,
                timing: 'linear',
                effect: 'cross-dissolve',
              }}
            />
            <View style={styles.nameAndText}>
              <TextTypeTimeslot>Booked</TextTypeTimeslot>
              <TextNameTimeslot>{firstName ? firstName : ''}</TextNameTimeslot>
              <View style={styles.textContainer}>
                <TextTimeEvent>{`${timeBegin} - ${timeEnd}`}</TextTimeEvent>
                <TextActEvent>{activityName}</TextActEvent>
              </View>
            </View>
          </View>
        </Animated.View>
      </GestureDetector>
      <Animated.ScrollView style={[styles.animated, animatedBorder]}>
        <InfoActivity activityViewDetail={agendaViewDetail} />
        <TourInfo activityViewDetail={agendaViewDetail} />
      </Animated.ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colourConst.green,
    marginRight: timeslots.margin,
    marginLeft: timeslots.margin,
    marginTop: timeslots.marginBottom,
    borderRadius: timeslots.radius,
    flexDirection: 'row',
    paddingBottom: timeslots.paddingBottom,
    paddingTop: timeslots.padding,
  },
  mainBody: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  animated: {
    backgroundColor: colourConst.white,
    marginHorizontal: timeslots.margin,
    borderBottomLeftRadius: timeslots.radius,
    borderBottomRightRadius: timeslots.radius,
    borderColor: colourConst.green,
  },
  image: {
    width: timeslots.imageWidth,
    height: timeslots.imageHeight,
    marginLeft: timeslots.imageMarginLeft,
    borderRadius: timeslots.imageBorderRadius,
  },
  textContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  nameAndText: {
    flex: 1,
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
});

export default BookingEvent;
