import { Notification } from '../types';
import Constants from 'expo-constants';
const { NEXT_PUBLIC_PEOPLE_BASE_URL } = Constants.expoConfig!.extra!;
const notificationsUrl = `${NEXT_PUBLIC_PEOPLE_BASE_URL}/notifications`;
export const getNotifications = (token: string, wave: string) =>
  fetch(
    `${notificationsUrl}/filter?notificationChannelTypeId=1&projectId=${wave}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((response) => {
    if (response.ok) {
      return response.json() as Promise<readonly Notification[]>;
    }

    throw new Error(
      `${response.status} Error when fetching notifications data`
    );
  });
export const updateNotifications = (
  token: string,
  participantId: string,
  notificationId: string
) => {
  const url = `${notificationsUrl}/${notificationId}/markasread`;
  return fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      ETag: '0',
    },
    body: JSON.stringify({
      id: notificationId,
      targetParticipantId: participantId,
    }),
  }).then((response) => {
    if (response.ok) {
      return response;
    } else {
      throw new Error(
        `${response.status} Error when updating notifications data`
      );
    }
  });
};

export const getPublicVapidKey = async (token: string) => {
  const url = `${NEXT_PUBLIC_PEOPLE_BASE_URL}/notification-subscriptions/public-key`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  const data = (await response.json()) as {
    OrganizationId: string;
    Key: string;
  };
  return data;
};
