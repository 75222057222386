import { TabIconProp } from '../../utils/types';
import { View, StyleSheet } from 'react-native';
import React from 'react';

const NotificationsIcon = ({ focused }: TabIconProp) => {
  return (
    <View style={styles.img}>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_1221_2289"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="28"
          height="28"
        >
          <path
            d="M13.1408 0H14.887C14.9653 0.0111932 15.0437 0.044773 15.122 0.044773C16.1294 0.111932 17.1256 0.302217 18.0882 0.604434C25.0952 2.75353 29.2367 9.83883 27.6696 17.0025C26.3264 23.1923 20.9201 27.7256 14.5848 27.9718C11.1261 28.1062 8.00315 27.0988 5.29439 24.9385C2.52967 22.7334 0.839507 19.868 0.212687 16.3757C0.123142 15.872 0.0671593 15.3683 0 14.8646C0 14.2825 0 13.7005 0 13.1184C0.0111932 13.0401 0.033596 12.9617 0.0447892 12.8834C0.134335 12.2454 0.201478 11.5962 0.335796 10.9693C1.46631 5.54064 6.1003 1.09693 11.5514 0.223864C12.0663 0.134319 12.5924 0.0783523 13.1184 0.011193L13.1408 0ZM20.8082 19.7112C20.8082 19.5545 20.797 19.4202 20.8082 19.2859C20.853 18.9837 20.741 18.771 20.5172 18.5695C20.1142 18.189 19.7336 17.786 19.3307 17.3943C19.1628 17.2375 19.0956 17.0808 19.0956 16.8458C19.1068 15.5698 19.1068 14.2937 19.0956 13.0177C19.0956 12.5476 19.062 12.0663 18.9725 11.6074C18.6143 9.79406 17.6741 8.4061 15.8832 7.7457C15.4019 7.56661 15.234 7.37633 15.2564 6.87263C15.2787 6.23462 14.7191 5.73092 14.0587 5.69735C13.4431 5.66377 12.861 6.12269 12.7603 6.72712C12.7267 6.9174 12.7267 7.10769 12.7379 7.29797C12.7491 7.48826 12.6931 7.56661 12.5028 7.62258C11.7529 7.82406 11.0925 8.19343 10.5328 8.7419C9.48066 9.77167 8.97696 11.0701 8.93219 12.514C8.88742 13.9803 8.921 15.4466 8.90981 16.9129C8.90981 17.0585 8.83146 17.2264 8.74191 17.3383C8.29418 17.8084 7.83526 18.2561 7.37634 18.7151C7.30918 18.7822 7.23083 18.8718 7.23083 18.9613C7.20844 19.2076 7.23083 19.4538 7.23083 19.7112H20.8082ZM15.6929 20.5955H12.3349C12.2901 21.491 13.0737 22.2633 14.0027 22.2745C14.9318 22.2745 15.7377 21.491 15.6929 20.5955Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_1221_2289)">
          <path
            d="M132.192 -6.04434H-268.436V34.8109H132.192V-6.04434Z"
            fill={focused ? 'url(#paint0_linear_1221_2289)' : '#AFAFAF'}
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_1221_2289"
            x1="-0.235043"
            y1="14.3833"
            x2="29.0352"
            y2="14.3833"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D30077" />
            <stop offset="1" stopColor="#8B3E8D" />
          </linearGradient>
        </defs>
      </svg>
    </View>
  );
};
const styles = StyleSheet.create({
  img: {
    width: 36,
    height: 80,
    alignItems: 'center',
  },
});

export default NotificationsIcon;
