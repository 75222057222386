import React from 'react';
import { View, StyleSheet, ImageBackground, Image } from 'react-native';
import CardData from '../CardData';
import TextCardTitle from '../TextComponents/Home/TextCardTitle';
import { colourConst, shadowConst } from '../../globalStyles';
import { useCollapibleCard } from './utils';
import TextCardLocation from '../TextComponents/Home/TextCardLocation';
import InfoActivity from '../InfoActivity';
import TourInfo from '../TourInfo';
import Animated, {
  useSharedValue,
  withTiming,
  useAnimatedStyle,
  Easing,
} from 'react-native-reanimated';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';
import AgendaItemInfo from '../AgendaItemInfo';
import { useSecureContext } from '../../context';
import Svg, { Defs, LinearGradient, Stop, Path } from 'react-native-svg';
import getColoursAndLocations from '../RainbowLine/utils';
import useGetAssets from '../../hooks/useGetAssets';
import TextEveningActivity from '../TextComponents/Home/TextEveningActivity';
import EveningActivityItemInfo from '../EveningActivityItemInfo';

interface CollapsibleCardProps {
  img: string;
  activity: string;
  time: string;
  date: string;
  location: string;
  activityType: string;
  meetingPoint?: string;
  activityViewDetail: { Name: string; Content: string }[];
}

const AnimatedImageBackground =
  Animated.createAnimatedComponent(ImageBackground);

const CollapsilbeCard = ({
  img: imageId,
  activity,
  time,
  date,
  location,
  activityType,
  activityViewDetail,
}: CollapsibleCardProps) => {
  const { locationAndWave } = useSecureContext();
  const isGoldenCircleWinner = locationAndWave?.wave?.ProjectRoles?.find(
    (wave) => wave.Reference === 'GCA'
  );

  const { colours, locations } = getColoursAndLocations(isGoldenCircleWinner);
  const {
    expandableStyle,
    touchHandler,
    handlerLayout,
    stateExpanded,
    contentHeight,
  } = useCollapibleCard();

  const { imageToDisplay: image, setImage: setTempImageUrl } =
    useGetAssets(imageId);

  const opacity = useSharedValue(0);
  const opacityContainer = useSharedValue(0);

  const animatedStyle = useAnimatedStyle(() => {
    return {
      opacity: opacity.value,
    };
  });
  const animatedContainerStyle = useAnimatedStyle(() => {
    return {
      opacity: opacityContainer.value,
    };
  });
  ///

  if (!setTempImageUrl) {
    return (
      <Image
        style={style.loader}
        source={require('../../../assets/loader.gif')}
      />
    );
  }

  return (
    <Animated.View
      style={[style.body, animatedContainerStyle]}
      onLayout={() => {
        opacityContainer.value = withTiming(1, {
          duration: 300,
          easing: Easing.linear,
        });
      }}
    >
      <View style={style.container}>
        <AnimatedImageBackground
          imageStyle={style.img}
          style={[style.background, animatedStyle]}
          source={image}
          onLoadStart={() => {
            opacity.value = withTiming(1, {
              duration: 300,
              easing: Easing.linear,
            });
          }}
        >
          <GestureDetector gesture={Gesture.Exclusive(touchHandler)}>
            <View style={style.extraLayer}>
              <Svg
                style={style.line}
                width="100%"
                height="100%"
                viewBox="0 0 100 30"
              >
                <Defs>
                  <LinearGradient id="gradient" x1="0" y1="0" x2="1" y2="0">
                    {colours.map((color, index) => (
                      <Stop
                        key={`color-${index}`}
                        offset={`${locations[index] * 100}%`}
                        stopColor={color}
                      />
                    ))}
                  </LinearGradient>
                </Defs>
                <Path
                  d="M-65,30 Q50,-25 165,30"
                  strokeWidth="3"
                  stroke="url(#gradient)"
                  strokeLinecap="round"
                  fill="none"
                />
              </Svg>
              <View style={style.data}>
                <CardData text={time} />
                <CardData text={date} />
              </View>
              <Animated.View
                style={[
                  style.expandable,
                  // eslint-disable-next-line react-native/no-inline-styles
                  {
                    borderBottomLeftRadius: stateExpanded ? 0 : 15,
                    borderBottomRightRadius: stateExpanded ? 0 : 15,
                  },
                ]}
                key={contentHeight}
              >
                <Svg width="100%" height="100%" viewBox="0 0 100 30">
                  <Path
                    d="M-35,30 Q50,-30 135,30"
                    fill={'rgba(255, 255, 255)'}
                    opacity={stateExpanded ? 1 : 0.8}
                  />
                </Svg>
                <View style={style.expandableContent}>
                  <TextCardTitle>{activity}</TextCardTitle>
                  {activityType === 'Evening Activity' ? (
                    <TextEveningActivity>{location}</TextEveningActivity>
                  ) : (
                    <TextCardLocation>{location}</TextCardLocation>
                  )}
                </View>
              </Animated.View>
            </View>
          </GestureDetector>
        </AnimatedImageBackground>
      </View>
      <Animated.View style={[style.animate, expandableStyle]}>
        <View onLayout={handlerLayout}>
          {activityType === 'Tour or Activity' && (
            <>
              <InfoActivity activityViewDetail={activityViewDetail} />
              <TourInfo activityViewDetail={activityViewDetail} />
            </>
          )}
          {/* Check if you can remove EveningActivityItemInfo */}
          {activityType === 'Evening Activity' && (
            <EveningActivityItemInfo activityViewDetail={activityViewDetail} />
          )}
          {activityType !== 'Tour or Activity' &&
            activityType !== 'Evening Activity' && (
              <AgendaItemInfo activityViewDetail={activityViewDetail} />
            )}
        </View>
      </Animated.View>
    </Animated.View>
  );
};

const style = StyleSheet.create({
  body: {
    width: '90%',
    alignSelf: 'center',
    marginVertical: 15,
    borderRadius: 15,
    shadowOpacity: shadowConst.opacity,
    elevation: shadowConst.elevation,
    shadowRadius: shadowConst.radius,
    shadowOffset: {
      width: shadowConst.width,
      height: shadowConst.height,
    },
  },
  container: {
    width: '100%',
    alignSelf: 'center',
    aspectRatio: 1.62,
    borderRadius: 15,
  },

  background: {
    flex: 1,
  },
  img: {
    borderRadius: 15,
  },

  extraLayer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  expandable: {
    width: '100%',
    aspectRatio: 3.5,
    justifyContent: 'center',
    // borderBottomLeftRadius: 15,
    // borderBottomRightRadius: 15,
    overflow: 'hidden',
  },
  expandableContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },

  animate: {
    backgroundColor: colourConst.white,
    width: '100%',
    alignSelf: 'center',
    borderBottomLeftRadius: 15,
    borderBottomRightRadius: 15,
    overflow: 'hidden',

    marginTop: -1,
  },
  data: {
    height: '30%',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'relative',
    top: '-25%',
  },
  loader: {
    width: 100,
    height: 100,
    alignSelf: 'center',
    resizeMode: 'contain',
  },
  line: {
    position: 'relative',
    top: '51.1%',
  },
});

export default CollapsilbeCard;
