import React, { useEffect } from 'react';
import { Text, StyleSheet, View } from 'react-native';
import { colourConst, fontSize, fontFamilyConst } from '../../../globalStyles';
import { RouteProp, useRoute } from '@react-navigation/native';
import { MoreParams } from '../../../utils/types';

const TitleItem = () => {
  const route = useRoute<RouteProp<MoreParams, 'Item'>>();
  const { Title } = route.params.item;

  useEffect(() => {
    document.title = Title;
  }, [Title]);

  return (
    <View style={styles.container}>
      <Text style={styles.text}>{Title}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  text: {
    fontFamily: fontFamilyConst.bold,
    fontSize: fontSize.large,
    color: colourConst.blue,
    textAlign: 'center',
  },
});
export default TitleItem;
