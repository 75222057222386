import { TabIconProp } from '../../utils/types';
import { View, StyleSheet } from 'react-native';
import React from 'react';

const SocialCircleIcon = ({ focused }: TabIconProp) => {
  return (
    <View style={styles.img}>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.496 13.328C18.6256 13.2496 17.1696 13.9664 16.3184 15.6576C15.4784 17.3488 15.8032 18.9392 16.9792 20.3728C15.4112 21.28 12.9248 21.3808 11.0992 20.3952C12.2976 18.9504 12.6224 17.3488 11.7712 15.6464C10.92 13.944 9.45279 13.2384 7.54879 13.328C7.89599 11.5696 8.79199 10.1696 10.2368 9.128C11.0768 10.5952 12.2976 11.4688 14.0112 11.4688C15.736 11.4688 16.9568 10.6064 17.7856 9.1392C19.0848 9.9792 20.2048 11.6144 20.4736 13.328H20.496ZM28 14C28 21.728 21.728 28 14 28C6.272 28 0 21.728 0 14C0 6.272 6.272 0 14 0C21.728 0 28 6.272 28 14ZM24.248 17.92V17.2032C24.192 16.912 24.1584 16.6096 24.0688 16.3296C23.7776 15.344 23.1952 14.5712 22.3328 14.0112C22.2208 13.944 22.176 13.8544 22.1648 13.72C22.0528 12.5776 21.7168 11.5024 21.1568 10.5056C20.4736 9.3072 19.5552 8.3328 18.3792 7.6048C18.2672 7.5376 18.2224 7.4704 18.2112 7.3248C18.2 7 18.1776 6.6752 18.0992 6.3616C17.4944 3.864 14.952 2.52 12.544 3.3936C10.9424 3.976 9.87839 5.5104 9.84479 7.3024C9.84479 7.4704 9.77759 7.5488 9.65439 7.6272C7.42559 9.0944 6.1712 11.1552 5.88 13.7984C5.88 13.8768 5.81279 13.9664 5.74559 14.0224C5.55519 14.1904 5.3424 14.3248 5.1632 14.4928C3.7856 15.7472 3.44959 17.976 4.40159 19.5776C5.46559 21.3808 7.4592 22.12 9.464 21.4592C9.5424 21.4368 9.65439 21.4592 9.73279 21.4928C9.99039 21.616 10.2368 21.784 10.5056 21.9072C12.04 22.6352 13.6528 22.8704 15.3328 22.6016C16.408 22.4336 17.4048 22.0528 18.3344 21.4928C18.4128 21.448 18.536 21.448 18.6256 21.4592C18.8832 21.5152 19.1408 21.6048 19.3984 21.6496C21.3248 21.9856 23.3072 20.8432 23.968 19.0064C24.0912 18.6592 24.1584 18.2896 24.248 17.9312V17.92Z"
          fill={focused ? 'url(#paint0_linear_1221_2268)' : '#AFAFAF'}
        />
        <mask
          id="mask0_1221_2268"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="28"
          height="28"
        >
          <path
            d="M20.496 13.328C18.6256 13.2496 17.1696 13.9664 16.3184 15.6576C15.4784 17.3488 15.8032 18.9392 16.9792 20.3728C15.4112 21.28 12.9248 21.3808 11.0992 20.3952C12.2976 18.9504 12.6224 17.3488 11.7712 15.6464C10.92 13.944 9.45279 13.2384 7.54879 13.328C7.89599 11.5696 8.79199 10.1696 10.2368 9.128C11.0768 10.5952 12.2976 11.4688 14.0112 11.4688C15.736 11.4688 16.9568 10.6064 17.7856 9.1392C19.0848 9.9792 20.2048 11.6144 20.4736 13.328H20.496ZM28 14C28 21.728 21.728 28 14 28C6.272 28 0 21.728 0 14C0 6.272 6.272 0 14 0C21.728 0 28 6.272 28 14ZM24.248 17.92V17.2032C24.192 16.912 24.1584 16.6096 24.0688 16.3296C23.7776 15.344 23.1952 14.5712 22.3328 14.0112C22.2208 13.944 22.176 13.8544 22.1648 13.72C22.0528 12.5776 21.7168 11.5024 21.1568 10.5056C20.4736 9.3072 19.5552 8.3328 18.3792 7.6048C18.2672 7.5376 18.2224 7.4704 18.2112 7.3248C18.2 7 18.1776 6.6752 18.0992 6.3616C17.4944 3.864 14.952 2.52 12.544 3.3936C10.9424 3.976 9.87839 5.5104 9.84479 7.3024C9.84479 7.4704 9.77759 7.5488 9.65439 7.6272C7.42559 9.0944 6.1712 11.1552 5.88 13.7984C5.88 13.8768 5.81279 13.9664 5.74559 14.0224C5.55519 14.1904 5.3424 14.3248 5.1632 14.4928C3.7856 15.7472 3.44959 17.976 4.40159 19.5776C5.46559 21.3808 7.4592 22.12 9.464 21.4592C9.5424 21.4368 9.65439 21.4592 9.73279 21.4928C9.99039 21.616 10.2368 21.784 10.5056 21.9072C12.04 22.6352 13.6528 22.8704 15.3328 22.6016C16.408 22.4336 17.4048 22.0528 18.3344 21.4928C18.4128 21.448 18.536 21.448 18.6256 21.4592C18.8832 21.5152 19.1408 21.6048 19.3984 21.6496C21.3248 21.9856 23.3072 20.8432 23.968 19.0064C24.0912 18.6592 24.1584 18.2896 24.248 17.9312V17.92Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_1221_2268)">
          <path
            d="M213.349 -6.048H-187.522V34.832H213.349V-6.048Z"
            fill={focused ? 'url(#paint1_linear_1221_2268)' : '#AFAFAF'}
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_1221_2268"
            x1="0"
            y1="14"
            x2="28"
            y2="14"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D2000C" />
            <stop offset="1" stopColor="#8B060F" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1221_2268"
            x1="-0.0672117"
            y1="14.392"
            x2="28"
            y2="14.392"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D2000C" />
            <stop offset="1" stopColor="#8B060F" />
          </linearGradient>
        </defs>
      </svg>
    </View>
  );
};
const styles = StyleSheet.create({
  img: {
    width: 36,
    height: 80,
    alignItems: 'center',
  },
});

export default SocialCircleIcon;
